import React, { useEffect, useState, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createOutline, getCurrentOutline } from '../../actions/outline';
import { toggleNav } from '../../actions/nav';
import OutlineTools from './OutlineTools';
import ActNav from './ActNav';
import SideNav from '../layout/SideNav';
import questions from './questions.json';
import shortenText from '../../utils/shortenText';
import ReactGA from 'react-ga';

const initialState = {
  act2a01: '',
  act2a02: '',
  act2a03: '',
  act2a04: '',
  act2a05: '',
  act2a06: '',
  act2a07: '',
  act2a08: '',
  act2a09: '',
  act2a10: '',
  act2a11: '',
  act2a12: '',
  act2a13: '',
  act2a14: '',
  act2a15: '',
  act2a16: '',
};

const questionState = {
  a02q01: true,
  a02q02: true,
  a02q03: true,
  a02q04: true,
  a02q05: true,
  a02q06: true,
  a02q07: true,
  a02q08: true,
  a02q09: true,
  a02q10: true,
  a02q11: true,
  a02q12: true,
};

const ActTwo = ({
  outline: { outline, outlineLoading },
  createOutline,
  getCurrentOutline,
  history,
  toggleNav,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [questionData, setQuestionData] = useState(questionState);

  useEffect(() => {
    if (!outline || Object.keys(outline).length === 0) getCurrentOutline();
    if (!outlineLoading && outline) {
      const outlineData = { ...initialState };
      for (const key in outline) {
        if (key in outlineData) outlineData[key] = outline[key];
      }
      setFormData(outlineData);
    }
  }, [outlineLoading, getCurrentOutline, outline]);

  const {
    act2a01,
    act2a02,
    act2a03,
    act2a04,
    act2a05,
    act2a06,
    act2a07,
    act2a08,
    act2a09,
    act2a10,
    act2a11,
    act2a12,
    // act2a13,
    // act2a14,
    // act2a15,
    // act2a16,
  } = formData;

  const {
    a02q01,
    a02q02,
    a02q03,
    a02q04,
    a02q05,
    a02q06,
    a02q07,
    a02q08,
    a02q09,
    a02q10,
    a02q11,
    a02q12,
  } = questionData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createOutline(formData, history, outline ? true : false);
  };

  const showMore = (e, q, qstring) => {
    e.preventDefault();
    setQuestionData({
      ...questionData,
      [qstring]: !q,
    });
  };

  return (
    <Fragment>
      <SideNav />
      <section>
        <div className='text-centered'>
          {outline && outline.title && (
            <p className='large'>"{outline.title}"</p>
          )}
          <ActNav outline={outline} />
        </div>

        <div className='text-centered my-2'>
          <h1 className='large text-primary'>The Hero's Journey</h1>

          <p className='lead'>
            <i className='fas fa-scroll'></i> Act II
            <br />
            Confrontation
          </p>

          <small>
            (Each question can be expanded for more information, as well as
            examples from famous works.)
          </small>
        </div>

        <form className='form' onSubmit={(e) => onSubmit(e)}>
          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>1:</strong>{' '}
                {questionData.a02q01 === true ? (
                  <span>{shortenText(questions.a02q01, 125)}</span>
                ) : (
                  <span>{questions.a02q01}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q01, 'a02q01')}
                  >
                    {questionData.a02q01 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a01'
              value={act2a01}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>2:</strong>{' '}
                {questionData.a02q02 === true ? (
                  <span>{shortenText(questions.a02q02, 125)}</span>
                ) : (
                  <span>{questions.a02q02}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q02, 'a02q02')}
                  >
                    {questionData.a02q02 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a02'
              value={act2a02}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>3: </strong>
                {questionData.a02q03 === true ? (
                  <span>{shortenText(questions.a02q03, 125)}</span>
                ) : (
                  <span>{questions.a02q03}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q03, 'a02q03')}
                  >
                    {questionData.a02q03 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a03'
              value={act2a03}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>4: THE FIRST PINCH POINT -</strong>{' '}
                {questionData.a02q04 === true ? (
                  <span>{shortenText(questions.a02q04, 125)}</span>
                ) : (
                  <span>{questions.a02q04}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q04, 'a02q04')}
                  >
                    {questionData.a02q04 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a04'
              value={act2a04}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>5:</strong>{' '}
                {questionData.a02q05 === true ? (
                  <span>{shortenText(questions.a02q05, 125)}</span>
                ) : (
                  <span>{questions.a02q05}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q05, 'a02q05')}
                  >
                    {questionData.a02q05 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a05'
              value={act2a05}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>6:</strong>{' '}
                {questionData.a02q06 === true ? (
                  <span>{shortenText(questions.a02q06, 125)}</span>
                ) : (
                  <span>{questions.a02q06}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q06, 'a02q06')}
                  >
                    {questionData.a02q06 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a06'
              value={act2a06}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>7: THE MIDPOINT </strong>{' '}
                {questionData.a02q07 === true ? (
                  <span>{shortenText(questions.a02q07, 125)}</span>
                ) : (
                  <span>{questions.a02q07}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q07, 'a02q07')}
                  >
                    {questionData.a02q07 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a07'
              value={act2a07}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>8:</strong>{' '}
                {questionData.a02q08 === true ? (
                  <span>{shortenText(questions.a02q08, 125)}</span>
                ) : (
                  <span>{questions.a02q08}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q08, 'a02q08')}
                  >
                    {questionData.a02q08 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a08'
              value={act2a08}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>
          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>9:</strong>{' '}
                {questionData.a02q09 === true ? (
                  <span>{shortenText(questions.a02q09, 125)}</span>
                ) : (
                  <span>{questions.a02q09}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q09, 'a02q09')}
                  >
                    {questionData.a02q09 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a09'
              value={act2a09}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>10:</strong>{' '}
                {questionData.a02q10 === true ? (
                  <span>{shortenText(questions.a02q10, 125)}</span>
                ) : (
                  <span>{questions.a02q10}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q10, 'a02q10')}
                  >
                    {questionData.a02q10 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a10'
              value={act2a10}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>11: THE SECOND PINCH POINT -</strong>{' '}
                {questionData.a02q11 === true ? (
                  <span>{shortenText(questions.a02q11, 125)}</span>
                ) : (
                  <span>{questions.a02q11}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q11, 'a02q11')}
                  >
                    {questionData.a02q11 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a11'
              value={act2a11}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>12:</strong>{' '}
                {questionData.a02q12 === true ? (
                  <span>{shortenText(questions.a02q12, 125)}</span>
                ) : (
                  <span>{questions.a02q12}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a02q12, 'a02q12')}
                  >
                    {questionData.a02q12 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act2a12'
              value={act2a12}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          {/* <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>13:</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias quis amet facilis nostrum ipsam. Explicabo repudiandae obcaecati dolorum voluptas aliquam.
              </small>
              <div className='info-caret'>
              
                <div className='infocareticon'>
                  <i className='fas fa-caret-down'></i>
                </div>
              </div>
            </div>
            <textarea
              name='act2a13'
              value={act2a13}
              onChange={(e) => onChange(e)}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>14:</strong> Lorem ipsum dolor sit amet, consectetur
                adipisicing elit. Esse tenetur rem doloribus eaque officia!
                Consectetur, voluptatum. Consequuntur sit, cupiditate at animi
                debitis sequi repudiandae repellat cumque! Dolor, delectus
                maiores quisquam numquam totam illum velit temporibus provident.
                Accusamus voluptates sed dignissimos magni veritatis voluptas
                tempore qui architecto nesciunt expedita aspernatur mollitia
                totam aliquam corporis fugit velit natus, fugiat consequuntur
                nihil assumenda? Velit iusto molestias harum veritatis corporis
                inventore dolore, quos quaerat hic error nam nisi quae quidem
                modi sunt impedit perspiciatis accusantium possimus. <br></br>
                Iusto, excepturi ipsa voluptates aliquam soluta autem dicta
                itaque doloribus, numquam dolores quis! Itaque impedit dolore
                sequi, ea ut illum blanditiis debitis distinctio dicta doloribus
                quam porro deserunt voluptates et laboriosam expedita sit libero
                molestiae aliquid repudiandae autem voluptas ullam sed. Ducimus
                laborum exercitationem a quidem fugiat beatae praesentium
                tenetur voluptas voluptatibus doloremque? Iste corrupti sit modi
                nobis beatae. Repellat quae omnis repudiandae illum beatae animi
                reprehenderit rem. Vero, quia! At nulla omnis ut beatae sunt?
                Nemo minima suscipit neque sed ratione dolore, perferendis nobis
                ad obcaecati, odit molestiae sequi nesciunt velit illum
                cupiditate. Nemo aperiam dolorem rerum similique nulla
                aspernatur, assumenda accusantium quia illum, adipisci porro
                molestias consectetur ut magni quod! Minus accusantium quidem
                unde reiciendis? Numquam<br></br>Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Libero fuga eligendi iusto
                corrupti cupiditate, molestias sapiente necessitatibus, quia
                deserunt officia cum voluptate, voluptates fugit sed veritatis
                optio quaerat placeat debitis praesentium amet. Voluptas nobis
                eligendi, ea reiciendis vitae dignissimos perferendis blanditiis
                quas ipsum atque maiores mollitia velit quibusdam magnam
                repudiandae porro delectus sit, ab ad magni ut consequuntur
                voluptatum esse. Amet quos tempore est, id quod porro
                reprehenderit? In soluta doloribus quis consequuntur accusantium
                quas? Deserunt modi rerum at impedit ullam quos doloribus
                maiores esse, aut, repellat minus dolor architecto autem.
                Ratione tempora molestiae perferendis iste dolorem aut beatae
                delectus?....
              </small>
              <div className='info-caret'>
                <span className='help-text'>Close</span>
                <div className='infocareticon'>
                  <i className='fas fa-caret-up'></i>
                </div>
              </div>
            </div>
            <textarea
              name='act2a14'
              value={act2a14}
              onChange={(e) => onChange(e)}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>15:</strong> Praesentium, soluta rerum ipsum dolor sit
                amet consectetur adipisicing elit. Modi eius vitae accusantium
                quis? ...
              </small>
              <div className='info-caret'>
              
                <div className='infocareticon'>
                  <i className='fas fa-caret-down'></i>
                </div>
              </div>
            </div>
            <textarea
              name='act2a15'
              value={act2a15}
              onChange={(e) => onChange(e)}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>16:</strong> Lorem, ipsum dolor sit amet consectetur
                adipisicing elit. Eveniet, rat`ione, sequi nisi ipsum laudantium
                atque nemo ...
              </small>
              <div className='info-caret'>
              
                <div className='infocareticon'>
                  <i className='fas fa-caret-down'></i>
                </div>
              </div>
            </div>
            <textarea
              name='act2a16'
              value={act2a16}
              onChange={(e) => onChange(e)}
              cols='30'
              rows='5'
            ></textarea>
          </div> */}

          <div className='text-centered'>
            <button
              type='submit'
              className='btn btn-primary my-1'
              onClick={(e) => {
                ReactGA.event({
                  category: 'Outline',
                  action: 'Saved outline from bottom-button (act 2)',
                });
              }}
            >
              <i className='far fa-save'></i> SAVE
            </button>
          </div>

          <OutlineTools />
        </form>
      </section>
    </Fragment>
  );
};

ActTwo.propTypes = {
  createOutline: PropTypes.func.isRequired,
  getCurrentOutline: PropTypes.func.isRequired,
  outline: PropTypes.object.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
});

export default connect(mapStateToProps, {
  createOutline,
  getCurrentOutline,
  toggleNav,
})(ActTwo);
