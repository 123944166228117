import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteWork } from '../../actions/profile';

const FinishedWorks = ({ works, deleteWork }) => {
  const allWorks = works.map((wrk) => (
    <tr key={wrk._id}>
      <td>{wrk.title}</td>
      <td className='hide-sm'>{wrk.author}</td>
      <td className='hide-sm'>{wrk.genre}</td>
      <td>
        <button onClick={() => deleteWork(wrk._id)} className='btn btn-danger'>
          Delete
        </button>
      </td>
    </tr>
  ));
  return (
    <Fragment>
      <h2 id='finished-works-header'>Finished Works</h2>
      <table className='table'>
        <thead>
          <tr>
            <th>Title</th>
            <th className='hide-sm'>Author</th>
            <th className='hide-sm'>Genre</th>
            <th />
          </tr>
        </thead>
        <tbody>{allWorks}</tbody>
      </table>
    </Fragment>
  );
};

FinishedWorks.propTypes = {
  works: PropTypes.array.isRequired,
  deleteWork: PropTypes.func.isRequired,
};

export default connect(null, { deleteWork })(FinishedWorks);
