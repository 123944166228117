import React, { Fragment } from 'react';
import Footer from '../../components/layout/Footer';

const About = (props) => {
  return (
    <Fragment>
      <div className='text-centered main-container'>
        <h1 className='large text-primary'>About Plot Producer</h1>
        <br></br>
        <p className='large'>How does it work?</p>
        <div className='text-left about-text-container'>
          <p>
            Plot Producer is the <strong>100% FREE</strong> system for outlining
            your story. Plot Producer can be used for any form of fiction
            writing. If you are writing a novel, short story, novella, or any
            other story that can be broken down into three acts, then Plot
            Producer is for you.
          </p>
          <br></br>
          <p>
            Plotting out a complex and layered story can be a complicated and
            potentially overwhelming task. Keeping track of all of the story
            beats in something as large as a novel can become confusing, not to
            mention remembering details about each of your characters and
            keeping track of their quirks and story goals. The faster you
            complete your story outline, the sooner you can move on to actually
            writing your book.
          </p>
          <br></br>
          <p>
            Created by an aspiring writer for aspiring writers, Plot Producer is
            the story development software designed to be very straight forward.{' '}
          </p>
          <p>
            <br />
            <strong>
              After signing up, from your Dashboard: click "start your outline",
              click on an act, and then start answering questions about your
              story.
            </strong>
          </p>
          <br />
          <p>
            The outline progress bar will fill as you complete the questions,
            and these questions not only help you to create an outline for your
            plot, but also to get the creative juices flowing. As you think more
            about your story, more ideas are likely to arise.
          </p>
          <br></br>
          <p>
            You can also quickly and freely create characters from the dashboard
            area. These characters can include a name, description, and as many
            additional notes as you would like. You can even include a photo to
            help set in your mind your idea of the character. Click "view all
            characters" in your dashboard to see all of your characters, and
            click each one to view the info in it's entirety.
          </p>
          <br></br>
          <p className='text-centered'>
            Outline your story online <strong>for free</strong> today with
            PlotProducer.com !
          </p>
        </div>
        <div className='line'></div>
        <br></br>
        <p className='large'>Contact Us</p>
        <p>
          <strong>Questions? Comments? Issues?</strong>
        </p>
        <p>
          {' '}
          Feel free to reach out to us by email. Please allow 12-48 hours for a
          response.
        </p>
        <br></br>
        <p>
          <a href='mailto: plotproducer@gmail.com'>plotproducer@gmail.com</a>
        </p>

        <p className='my-2'>
          <small>
            [ Created by{' '}
            <a href='https://www.conquercoalition.com'>Conquer Coalition</a> ]
          </small>
        </p>
        <Footer />
      </div>
    </Fragment>
  );
};

export default About;
