import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const MobileNav = ({ outline }) => {
  return (
    <Fragment>
      <div className='mobnav'>
        <div className='mobnav__item'>
          <Link to='/act-1' className='mobnav__itemlink'>
            <i className='fas fa-pencil-alt'></i>
          </Link>
          <br />
          <div className='mobnav__itemdesc'>ACTS 1-3</div>
        </div>
        <div className='mobnav__item'>
          <Link to='/outlineoutput' className='mobnav__itemlink'>
            <i className='fas fa-scroll'></i>
          </Link>
          <br />
          <div className='mobnav__itemdesc'>VIEW PLOT</div>
        </div>
        <div className='mobnav__item'>
          <Link to='/create-character' className='mobnav__itemlink'>
            <i className='fas fa-user-plus'></i>
          </Link>
          <br />
          <div className='mobnav__itemdesc'>CREATE CHAR</div>
        </div>
        <div className='mobnav__item'>
          <Link to='/outlines/characters/me' className='mobnav__itemlink'>
            <i className='fas fa-users'></i>
          </Link>
          <br />
          <div className='mobnav__itemdesc'>VIEW CHARS</div>
        </div>
      </div>
    </Fragment>
  );
};

export default MobileNav;
