import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../auth/Login';
import Register from '../auth/Register';
import Alert from '../layout/Alert';
import Dashboard from '../dashboard/Dashboard';
import ProfileForm from '../profile-forms/ProfileForm';
import ActOne from '../outline/ActOne';
import ActTwo from '../outline/ActTwo';
import ActThree from '../outline/ActThree';
import CreateCharacter from '../outline/CreateCharacter';
import CharacterList from '../characters/CharacterList';
import AddNoteForm from '../characters/AddNoteForm';
import AddWork from '../profile-forms/AddWork';
import Profiles from '../profiles/Profiles';
import Profile from '../profile/Profile';
import About from '../about/About';
import Privacy from '../about/Privacy';
import Guidelines from '../about/Guidelines';
import Verify from '../auth/Verify';
import ResendVerify from '../auth/ResendVerify';
import NotFound from '../layout/NotFound';
import TermsOfService from '../about/TermsOfService';
import PrivateRoute from '../routing/PrivateRoute';
import CharacterFullInfo from '../characters/CharacterFullInfo';
import OutlineOutput from '../outline/OutlineOutput';
import Posts from '../posts/Posts';
import Post from '../post/Post';

const Routes = () => {
  return (
    <section className='container'>
      <Alert />
      <Switch>
        <Route exact path='/register' component={Register} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/profile/:id' component={Profile} />
        <Route exact path='/profiles' component={Profiles} />
        <Route exact path='/about' component={About} />
        <Route exact path='/tos' component={TermsOfService} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/guidelines' component={Guidelines} />
        <Route exact path='/verify/:token' component={Verify} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/create-profile' component={ProfileForm} />
        <PrivateRoute exact path='/edit-profile' component={ProfileForm} />
        <PrivateRoute exact path='/add-work' component={AddWork} />
        <PrivateRoute exact path='/act-1' component={ActOne} />
        <PrivateRoute exact path='/act-2' component={ActTwo} />
        <PrivateRoute exact path='/act-3' component={ActThree} />
        <PrivateRoute
          exact
          path='/create-character'
          component={CreateCharacter}
        />
        <PrivateRoute
          exact
          path='/outlines/characters/me'
          component={CharacterList}
        />
        <PrivateRoute
          exact
          path='/outlines/characters/:id'
          component={CharacterFullInfo}
        />
        <PrivateRoute
          exact
          path='/outlines/characters/note/:id'
          component={AddNoteForm}
        />
        <PrivateRoute exact path='/posts' component={Posts} />
        <PrivateRoute exact path='/post/:id' component={Post} />
        <PrivateRoute exact path='/resend' component={ResendVerify} />
        <PrivateRoute exact path='/outlineoutput' component={OutlineOutput} />
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
