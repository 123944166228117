import React from 'react';
import { Link } from 'react-router-dom';

const DashboardActions = () => {
  return (
    <div className='dash-buttons my-2'>
      <Link to='/edit-profile' className='btn btn-light'>
        <i className='fas fa-address-card text-primary'></i> Edit Profile
      </Link>
      <Link to='/add-work' className='btn btn-light'>
        <i className='fas fa-book text-primary'></i> Add Finished Works
      </Link>
    </div>
  );
};

export default DashboardActions;
