import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentOutline } from '../../actions/outline';
import questions from './questions.json';

const OutlineOutput = ({
  outline: { outline, outlineLoading },
  getCurrentOutline,
  history,
}) => {
  useEffect(() => {
    if (!outline || Object.keys(outline).length === 0) getCurrentOutline();
  }, [getCurrentOutline, outline]);

  const {
    title,
    act1a01,
    act1a02,
    act1a03,
    act1a04,
    act1a05,
    act1a06,
    act1a07,
    act1a08,
    act2a01,
    act2a02,
    act2a03,
    act2a04,
    act2a05,
    act2a06,
    act2a07,
    act2a08,
    act2a09,
    act2a10,
    act2a11,
    act2a12,
    act2a13,
    act2a14,
    act2a15,
    act2a16,
    act3a01,
    act3a02,
    act3a03,
    act3a04,
    act3a05,
    act3a06,
    act3a07,
    act3a08,
  } = outline;

  const {
    a01q01,
    a01q02,
    a01q03,
    a01q04,
    a01q05,
    a01q06,
    a01q07,
    a01q08,
    a02q01,
    a02q02,
    a02q03,
    a02q04,
    a02q05,
    a02q06,
    a02q07,
    a02q08,
    a02q09,
    a02q10,
    a02q11,
    a02q12,
    a02q13,
    a02q14,
    a02q15,
    a02q16,
    a03q01,
    a03q02,
    a03q03,
    a03q04,
    a03q05,
    a03q06,
    a03q07,
    a03q08,
  } = questions;

  return (
    <Fragment>
      <div className='text-centered'>
        <Link to='/dashboard' className='btn btn-light btn-rounded my-2'>
          BACK
        </Link>

        <h1>"{title && title}"</h1>

        <br />

        <div className='act-output'>
          <div className='lead'>
            <h2>ACT I</h2>
            <div className='divider'></div>
          </div>
          {act1a01 ? (
            <p>
              <strong>{a01q01}</strong>
              <br />
              {act1a01}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act1a02 ? (
            <p>
              <strong>{a01q02}</strong>
              <br />
              {act1a02}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act1a03 ? (
            <p>
              <strong>{a01q03}</strong>
              <br />
              {act1a03}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act1a04 ? (
            <p>
              <strong>{a01q04}</strong>
              <br />
              {act1a04}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act1a05 ? (
            <p>
              <strong>{a01q05}</strong>
              <br />
              {act1a05}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act1a06 ? (
            <p>
              <strong>{a01q06}</strong>
              <br />
              {act1a06}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act1a07 ? (
            <p>
              <strong>{a01q07}</strong>
              <br />
              {act1a07}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act1a08 ? (
            <p>
              <strong>{a01q08}</strong>
              <br />
              {act1a08}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
        </div>

        <br />

        <div className='act-output'>
          <div className='lead'>
            <h2>ACT II</h2>
            <div className='divider'></div>
          </div>
          {act2a01 ? (
            <p>
              <strong>{a02q01}</strong>
              <br />
              {act2a01}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a02 ? (
            <p>
              <strong>{a02q02}</strong>
              <br />
              {act2a02}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a03 ? (
            <p>
              <strong>{a02q03}</strong>
              <br />
              {act2a03}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a04 ? (
            <p>
              <strong>{a02q04}</strong>
              <br />
              {act2a04}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a05 ? (
            <p>
              <strong>{a02q05}</strong>
              <br />
              {act2a05}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a06 ? (
            <p>
              <strong>{a02q06}</strong>
              <br />
              {act2a06}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a07 ? (
            <p>
              <strong>{a02q07}</strong>
              <br />
              {act2a07}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a08 ? (
            <p>
              <strong>{a02q08}</strong>
              <br />
              {act2a08}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a09 ? (
            <p>
              <strong>{a02q09}</strong>
              <br />
              {act2a09}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a10 ? (
            <p>
              <strong>{a02q10}</strong>
              <br />
              {act2a10}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a11 ? (
            <p>
              <strong>{a02q11}</strong>
              <br />
              {act2a11}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a12 ? (
            <p>
              <strong>{a02q12}</strong>
              <br />
              {act2a12}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a13 ? (
            <p>
              <strong>{a02q13}</strong>
              <br />
              {act2a13}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a14 ? (
            <p>
              <strong>{a02q14}</strong>
              <br />
              {act2a14}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a15 ? (
            <p>
              <strong>{a02q15}</strong>
              <br />
              {act2a07}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act2a16 ? (
            <p>
              <strong>{a02q16}</strong>
              <br />
              {act2a08}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
        </div>

        <br />

        <div className='act-output'>
          <div className='lead'>
            <h2>ACT III</h2>
            <div className='divider'></div>
          </div>
          {act3a01 ? (
            <p>
              <strong>{a03q01}</strong>
              <br />
              {act3a01}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act3a02 ? (
            <p>
              <strong>{a03q02}</strong>
              <br />
              {act3a02}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act3a03 ? (
            <p>
              <strong>{a03q03}</strong>
              <br />
              {act3a03}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act3a04 ? (
            <p>
              <strong>{a03q04}</strong>
              <br />
              {act3a04}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act3a05 ? (
            <p>
              <strong>{a03q05}</strong>
              <br />
              {act3a05}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act3a06 ? (
            <p>
              <strong>{a03q06}</strong>
              <br />
              {act3a06}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act3a07 ? (
            <p>
              <strong>{a03q07}</strong>
              <br />
              {act3a07}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
          {act3a08 ? (
            <p>
              <strong>{a03q08}</strong>
              <br />
              {act3a08}
              <br />
              <br />
            </p>
          ) : (
            <p>...</p>
          )}
        </div>
        <br />
      </div>
    </Fragment>
  );
};

OutlineOutput.propTypes = {
  getCurrentOutline: PropTypes.func.isRequired,
  outline: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
});

export default connect(mapStateToProps, {
  getCurrentOutline,
})(OutlineOutput);
