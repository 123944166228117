import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deleteCharacter } from '../../actions/outline';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

const CharacterItem = ({ character, deleteCharacter, history }) => {
  const shortenDesc = (description) => {
    if (description && description.length < 145) {
      return description;
    } else {
      return description.slice(0, 144) + ' ...';
    }
  };

  return (
    <Fragment>
      {!character && character !== null ? (
        <Fragment>NO</Fragment>
      ) : (
        <Fragment>
          <div className='character-card'>
            <div className='char-desc'>
              <p>
                <strong>Description: </strong>
                <br />
                {/* {character.desc} */}

                <span className='short-desc-text'>
                  {shortenDesc(character.desc)}
                </span>
              </p>
            </div>
            <div className='char-img'>
              <div className='char-info'>
                {/* <strong>Name: </strong>
              <br /> */}
                <div className='char-name'>{character.name}</div>
              </div>
              {character.photoUrl && <img src={character.photoUrl} alt='' />}
              <div className='protag-tag'>
                <p>
                  <strong>Protagonist:</strong>
                </p>

                {character.protag.toString().toUpperCase() === 'TRUE' ? (
                  <i className='fas fa-check text-primary'></i>
                ) : (
                  <i className='fas fa-times text-neg'></i>
                )}
              </div>
            </div>
            <div className='char-card-more'>
              <Link
                to={`/outlines/characters/${character._id}`}
                className='btn btn-primary btn-rounded'
              >
                <span className='char-card-more-text'>FULL INFO</span>
              </Link>
            </div>
            <div className='char-card-delete'>
              <div className='char-card-delete-text'>DELETE THIS CHARACTER</div>
              <button
                className='btn-rounded1'
                onClick={() => {
                  deleteCharacter(character._id, history);
                  ReactGA.event({
                    category: 'Character',
                    action:
                      'Deleted character from characters list (if accepted prompt)',
                  });
                }}
              >
                <span className='trash-btn'>
                  <i className='fas fa-trash '></i>
                </span>
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

CharacterItem.propTypes = {
  character: PropTypes.object.isRequired,
  deleteCharacter: PropTypes.func.isRequired,
};

export default connect(null, { deleteCharacter })(CharacterItem);
