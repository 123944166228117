import React, { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Landing = ({ isAuthenticated }) => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <Fragment>
      <div className='landing-container'>
        <section className='landing'>
          <div className='landing-message'>
            <h1 className='large'>Plot Producer</h1>
            <p>
              The <strong>FREE</strong> story plotting system that combines
              <strong> creativity</strong> and<strong> productivity</strong>.
            </p>
            <div className='landing-buttons'>
              <Link to='/register' className='btn btn-primary'>
                Sign Up
              </Link>
              <Link to='/login' className='btn'>
                Login
              </Link>
            </div>
          </div>
        </section>
      </div>
      <div className='parallax'>
        <div
          className='landing-bg-a'
          style={{ transform: `translateY(-${offsetY * 0.5}px)` }}
        ></div>
        <div
          className='landing-bg-b'
          style={{ transform: `translateY(-${offsetY * 0.75}px)` }}
        ></div>
        <div className='landing-content'>
          <div className='step-outline'>
            <div className='step1-container'>
              <div className='step1-title'>
                <h2>
                  STEP <span className='step-number'>1</span>
                </h2>
              </div>
              <div className='step1-desc'>
                <Link to='/register'>Sign up for an account</Link>. <br />
                <br />
                <p>
                  Registering is free, easy, and normally takes less than one
                  minute.
                </p>
                <p className='text-centered py-3'>
                  <Link to='/register' className='btn btn-primary'>
                    SIGN UP
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className='step-outline'>
            <div className='step2-container'>
              <div className='step2-title'>
                <h2>
                  STEP <span className='step-number'>2</span>
                </h2>
              </div>
              <div className='step2-desc'>
                <p>
                  Click the
                  <strong> "Start Your Outline"</strong> on your dashboard.
                  <br />
                  <br /> Then answer any of the questions (or simply name your
                  story) and you'll be able to save your outline.
                </p>
              </div>
            </div>
          </div>
          <div className='step-outline'>
            <div className='step3-container'>
              <div className='step3-title'>
                <h2>
                  STEP <span className='step-number'>3</span>
                </h2>
              </div>
              <div className='step3-desc'>
                <p>
                  Continue answering questions for each act of your plot at your
                  own pace.
                </p>
                <br />
                <p>
                  As you answer questions the progress bar on your dashboard
                  will grow, indicating your outline progress.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='domore__header'>
        <h2>DO MORE WITH YOUR STORY</h2>
      </div>
      <div className='domore__container'>
        <div className='domore__img1'></div>

        <div className='domore__desc1'>
          <h3>CREATE AND TRACK YOUR CHARACTERS</h3>
          <p>
            You can quickly and easily create and track the characters from your
            story with just a name and an image. Add not only a description of
            your characters, but also unlimited notes. These notes can be
            physical attributes, character traits, or anything else that you
            feel is important to keep track of for the characters in your story.
          </p>
        </div>
        <div className='domore__img2'></div>
        <div className='domore__desc2'>
          <h3>BRAINSTORM WITH OTHERS</h3>
          <p>
            In the brainstorming area, you can post ideas, concepts, loglines,
            or even entire outlines in order to get feedback from others. You
            can also like/vote for suggestions and comments, give your feedback
            to others seeking advice on their plots, and also report/flag posts
            that violate our community guidelines.
          </p>
        </div>
      </div>
      <div className='landing-footer'>
        <h1 className='text-centered'>
          GET STARTED WITH PLOTTING YOUR STORY NOW!
        </h1>
        <p className='text-centered'>
          Plot Producer is entirely <strong>FREE</strong>, and signing up takes
          only a few seconds.
        </p>
        <div className='signup-cta'>
          <Link to='/register' className='btn btn-primary'>
            SIGN UP
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
