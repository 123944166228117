import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { deleteCharacter, deleteNote } from '../../actions/outline';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';

const CharacterItemFullInfo = ({
  character,
  outline: { outline, outlineLoading },
  deleteCharacter,
  deleteNote,
  history,
}) => {
  return (
    <Fragment>
      {character === null || outlineLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className='char-card-more'>
            <Link
              to='/outlines/characters/me'
              className='btn btn-light btn-rounded my-1'
            >
              BACK
            </Link>
          </div>
          <div className='character-card'>
            <div className='char-desc'>
              <p>
                <strong>Description: </strong>
              </p>
              <br />
              {/* DESC */}
              <div
                className='full-user-text'
                dangerouslySetInnerHTML={{ __html: character.desc }}
              ></div>
              <div className='full-user-text'></div>
            </div>
            <div className='char-notes'>
              <div>
                {character.notes.length > 0 && <div className='divider'></div>}
              </div>
              <ul>
                {character.notes.map((note, index) => (
                  <div key={index}>
                    <div className='note-text'>
                      <strong>{note.noteTitle}</strong> <br />
                      {note.noteText}
                    </div>
                    <div className='note-delete-btn'>
                      <button
                        onClick={() => {
                          deleteNote(character._id, note._id, history);
                          ReactGA.event({
                            category: 'Character',
                            action:
                              'Deleted note from full info component (if accepted prompt)',
                          });
                        }}
                        className='sml-fnt btn-delete-note'
                      >
                        <i className='fas fa-times'></i>
                      </button>
                    </div>
                  </div>
                ))}
              </ul>
            </div>
            <div className='char-img'>
              <div className='char-info'>
                <div className='char-name'>{character.name}</div>
              </div>
              {character.photoUrl && <img src={character.photoUrl} alt='' />}

              <div className='protag-tag full-size-protag'>
                <p>
                  <strong>Protagonist:</strong>
                </p>

                {character.protag.toString().toUpperCase() === 'TRUE' ? (
                  <i className='fas fa-check text-primary'></i>
                ) : (
                  <i className='fas fa-times text-neg'></i>
                )}
              </div>
            </div>

            <div className='char-card-addNote'>
              <Link to={`/outlines/characters/note/${character._id}`}>
                <button
                  className='btn-add-note'
                  onClick={() => {
                    ReactGA.event({
                      category: 'Character',
                      action:
                        'Clicked to add note from full info char component',
                    });
                  }}
                >
                  <i className='fas fa-plus '></i> Add Note
                </button>
              </Link>
            </div>

            <div className='char-card-delete'>
              <div className='char-card-delete-text'>DELETE THIS CHARACTER</div>
              <button
                className='btn-rounded1'
                onClick={() => {
                  deleteCharacter(character._id, history);
                  ReactGA.event({
                    category: 'Character',
                    action:
                      'Deleted character from full info page (if accepted prompt)',
                  });
                }}
              >
                <i className='fas fa-trash '></i>
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

CharacterItemFullInfo.propTypes = {
  character: PropTypes.object.isRequired,
  deleteCharacter: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  outline: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
});

export default connect(mapStateToProps, { deleteCharacter, deleteNote })(
  CharacterItemFullInfo
);
