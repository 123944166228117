import axios from 'axios';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_PROFILE,
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Load a User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

// Register a User
export const register = ({
  name,
  email,
  password,
  ageVerify,
  tosAgree,
}) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ name, email, password, ageVerify, tosAgree });

  try {
    const res = await axios.post('/api/users', body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({ type: REGISTER_FAIL });
  }
};

// Login a User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post('/api/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({ type: LOGIN_FAIL });
  }
};

// Logout / Clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};

// Verify user's email
export const verifyEmail = (token, history) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/auth/verify/${token}`);

    dispatch(setAlert('Email Verified', 'success'));

    if (res.status === 200) {
      history.push('/dashboard');
    }

    // dispatch({
    //   type: VERIFY_EMAIL,
    //   payload: res.data,
    // });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

// Resend User's Verification Email
export const resendVerify = (history) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/auth/resend`);

    dispatch(setAlert('Verification Email Sent', 'success'));

    if (res.status === 200) {
      history.push('/dashboard');
    }
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};
