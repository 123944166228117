import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

const Footer = ({ auth: { isAuthenticated, loading }, logout }) => {
  const authLinks = (
    <Fragment>
      <div className='footer-col1'>
        <ul>
          <li>
            <Link to='/privacy'>Privacy Policy</Link>
          </li>
          <li>
            <Link to='/tos'>Terms Of Service</Link>
          </li>
          <li>
            <Link to='/guidelines'>Community Guidelines</Link>
          </li>
        </ul>
      </div>
      <div className='footer-col2'>
        <ul>
          <li>
            <Link to='/'></Link>
          </li>
          <li>
            <Link to='/'></Link>
          </li>
          <li>
            <Link to='/'></Link>
          </li>
        </ul>
      </div>
      <div className='footer-col3'>
        <ul>
          <li>
            <Link to='/about'>About</Link>
          </li>
          <li>
            <Link to='/dashboard'>Dashboard</Link>
          </li>
          <li>
            <a href='#!' onClick={logout}>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      <div className='footer-col1'>
        <ul>
          <li>
            <Link to='/privacy'>Privacy Policy</Link>
          </li>
          <li>
            <Link to='/tos'>Terms Of Service</Link>
          </li>
          <li>
            <Link to='/guidelines'>Community Guidelines</Link>
          </li>
        </ul>
      </div>
      <div className='footer-col2'>
        <ul>
          <li>
            <Link to='/'></Link>
          </li>
          <li>
            <Link to='/'></Link>
          </li>
          <li>
            <Link to='/'></Link>
          </li>
        </ul>
      </div>
      <div className='footer-col3'>
        <ul>
          <li>
            <Link to='/about'>About</Link>
          </li>
          <li>
            <Link to='/register'>Sign Up</Link>
          </li>
          <li>
            <Link to='/login'>Log In</Link>
          </li>
        </ul>
      </div>
    </Fragment>
  );

  return (
    <div className='footer sml-fnt'>
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks} </Fragment>
      )}
    </div>
  );
};

Footer.propTypes = {
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Footer);
