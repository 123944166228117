import React, { Fragment } from 'react';

const NotFound = () => {
  return (
    <Fragment>
      <div className='text-centered my-4'>
        <h1 className='x-large text-primary'>
          <i className='fas fa-exclamation-triangle'></i>
          <p> Page Not Found</p>
        </h1>
        <p className='large'>Oops.</p>
        <p>The requested page could not be found.</p>
      </div>
    </Fragment>
  );
};

export default NotFound;
