import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ProfileAbout = ({
  profile: {
    bio,
    genres,
    user: { name },
    amazonauthor,
  },
}) => (
  <div className='profile-about'>
    {bio && (
      <Fragment>
        {' '}
        <h2 className='text-primary'>{name.trim('').split(' ')[0]}'s Bio</h2>
        <p>{bio}</p>
      </Fragment>
    )}
    <div className='author-link'>
      {amazonauthor && (
        <p className='py-1'>
          Author's Link:
          <br />
          <br />
          <a href={amazonauthor}>
            <button className='btn btn-primary'> Go To Author's Link</button>
          </a>
          <br />
          <span className='text-light'>
            <small>(This is an outside link. Continue with caution)</small>
          </span>
        </p>
      )}
    </div>
    <div className='line'></div>
    <h2 className='text-primary'>Genres</h2>
    <div className='genres'>
      {genres.map((genre, index) => (
        <div key={index} className='p-1'>
          <i className='fas fa-caret-right'></i> {genre}
        </div>
      ))}
    </div>
  </div>
);

ProfileAbout.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileAbout;
