import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const StoryStartPrompt = () => {
  return (
    <div className='dash-buttons my-5'>
      <Link
        to='/act-1'
        className='btn btn-primary btn-round'
        onClick={(e) => {
          ReactGA.event({
            category: 'Outline',
            action: 'Clicked the Start Outline button',
          });
        }}
      >
        <strong>START OUTLINE</strong>
      </Link>
    </div>
  );
};

export default StoryStartPrompt;
