import React, { Fragment } from 'react';

const shortenText = (text, limit) => {
  if (text && text.length < limit) {
    return text;
  } else {
    return (
      <Fragment>
        <span>{text.slice(0, limit - 1)} ...</span>
        <span className='sml-fnt text-light'></span>
      </Fragment>
    );
  }
};

export default shortenText;
