import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { addLike, deletePost, addReport } from '../../actions/post';

const PostItem = ({
  addLike,
  addReport,
  deletePost,
  auth,
  post: { _id, text, name, avatar, user, likes, comments, date, reports },
}) => {
  const shortenText = (text) => {
    if (text && text.length < 345) {
      return text;
    } else {
      return (
        <Fragment>
          <span>{text.slice(0, 344)} ...</span>
          <span className='sml-fnt text-light'>
            {' '}
            (Click "discussion" to read more)
          </span>
        </Fragment>
      );
    }
  };

  return (
    <div className='post bg-white my-1 p-1'>
      <div>
        <Link to={`/profile/${user}`}>
          <img className='round-img' src={avatar} alt='Avatar' />
          <h4>{name}</h4>
        </Link>
      </div>

      <div className='post-buttons'>
        <div className='post-text'>
          <p className='my-1'>{shortenText(text)}</p>
        </div>
        <div className='post-date sml-fnt'>
          <div>
            Posted on <Moment format='h:mm a on MMMM Do, YYYY'>{date}</Moment>
          </div>
        </div>
        <div className='post-like'>
          <button
            className='btn btn-light btn-rounded'
            onClick={(e) => addLike(_id)}
          >
            <i className='fas fa-star'></i>
            {likes && likes.length > 0 && <span> {likes.length}</span>}
          </button>
        </div>
        <div className='post-comments'>
          <Link
            to={`/post/${_id}`}
            className='btn btn-primary comments-btn btn-rounded'
          >
            <small>
              Discussion{' '}
              {comments && comments.length > 0 && (
                <span className='comment-count'>{comments.length}</span>
              )}
            </small>
          </Link>
        </div>
        {!auth.loading &&
          (user === auth.user._id ||
            auth.user.role === 'admin' ||
            auth.user.role === 'mod') && (
            <div className='post-delete'>
              <button
                type='button'
                className='btn btn-trash-red'
                onClick={(e) => deletePost(_id)}
              >
                <i className='fas fa-trash'></i>
              </button>
            </div>
          )}

        <div className='post-report'>
          <button
            type='button'
            className='btn report-btn'
            onClick={(e) => addReport(_id)}
          >
            <i className='fas fa-flag'></i>
          </button>
          <span className='report-text'>
            Report this post for violating our guidelines{' '}
            {reports && reports.length > 0 && (
              <small>{reports.length} reports</small>
            )}
          </span>{' '}
        </div>
      </div>
    </div>
  );
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  addReport: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { addLike, deletePost, addReport })(
  PostItem
);
