import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addNote } from '../../actions/outline';
import examples from './examples.json';
import ReactGA from 'react-ga';

const NoteSuggestions = ({ addNote, match, history }) => {
  const [sugg, setSugg] = useState('');

  // const id = match.params.id.toString();

  const getRandomNum = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  const sugCount = examples.length;

  const randomSuggestion = () => {
    setSugg(examples[getRandomNum(sugCount)]);
  };

  return (
    <Fragment>
      <div className='text-centered'>
        <button
          className='btn btn-primary btn-rounded'
          onClick={(e) => {
            randomSuggestion();
            ReactGA.event({
              category: 'Character',
              action: 'Clicked random note suggestion button',
            });
          }}
        >
          GENERATE RANDOM EXAMPLE
        </button>{' '}
        {sugg && (
          <Fragment>
            <p>
              <br /> 
            </p>
            <div className='note-sugg-card'>
              <p>
                <strong>{sugg.sugTitle}</strong>
              </p>
              <p>{sugg.sugText}</p>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

NoteSuggestions.propTypes = {
  addNote: PropTypes.func.isRequired,
};

export default connect(null, { addNote })(NoteSuggestions);
