import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
// import Footer from '../../components/layout/Footer';

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    tosAgree: false,
    ageVerify: false,
  });

  const { name, email, password, password2, tosAgree, ageVerify } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const tosSwitch = (e) => setFormData({ ...formData, tosAgree: !tosAgree });

  const ageVerifySwitch = (e) =>
    setFormData({ ...formData, ageVerify: !ageVerify });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register({
        name,
        email,
        password,
        tosAgree,
        ageVerify,
      });
      ReactGA.event({
        category: 'User',
        action: 'Created an Account',
      });
    }
  };

  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <Fragment>
      <div className='text-centered main-container'>
        <h1 className='large text-primary'>Sign Up</h1>
        <p className='lead'>
          Create a <strong>FREE</strong> account and get started on your plot
          right away!
        </p>
        <div className='fit-form'>
          <form className='form' onSubmit={(e) => onSubmit(e)}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Name'
                name='name'
                value={name}
                onChange={(e) => onChange(e)}
                required={true}
                minLength='5'
              />
            </div>
            <div className='form-group'>
              <input
                type='email'
                placeholder='Email Address'
                name='email'
                value={email}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Password'
                minLength='6'
                name='password'
                value={password}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Confirm Password'
                minLength='9'
                name='password2'
                value={password2}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group '>
              <div className=''>
                <input
                  type='checkbox'
                  name='tosAgree'
                  value={tosAgree}
                  required={true}
                  onChange={(e) => tosSwitch(e)}
                />{' '}
                <span className='sml-fnt'>
                  {' '}
                  I have read and agree to the{' '}
                  <Link to='/privacy'>Privacy Policy</Link> and{' '}
                  <Link to='/tos'>T.O.S.</Link>
                </span>
              </div>
            </div>
            <div className='form-group '>
              <div className='m-negtop1'>
                <input
                  type='checkbox'
                  name='ageVerify'
                  value={ageVerify}
                  required={true}
                  onChange={(e) => ageVerifySwitch(e)}
                />{' '}
                <span className='sml-fnt'>I am over the age of 13.</span>
              </div>
            </div>

            <input type='submit' value='Sign Up' className='btn btn-primary' />
          </form>
        </div>
        <p className='my-1'>
          Already signed up? <Link to='/login'>Log In</Link>
        </p>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
