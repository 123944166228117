import axios from 'axios';
import { setAlert } from './alert';
import {
  DELETE_POST,
  GET_POSTS,
  GET_POST,
  POST_ERROR,
  UPDATE_LIKES,
  TOGGLE_LIKE,
  TOGGLE_VOTE,
  UPDATE_REPORTS,
  TOGGLE_REPORT,
  TOGGLE_COMMENT_REPORT,
  ADD_POST,
  ADD_COMMENT,
  DELETE_COMMENT,
} from './types';

// Get Posts
export const getPosts = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/posts');
    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get a single Post by ID
export const getPost = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/posts/${id}`);
    dispatch({
      type: GET_POST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Toggle Like to a Post (on 'Posts' component)
export const addLike = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/posts/like/${id}`);
    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Toggle Like on Post (on individual Post page)
export const toggleLike = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/posts/like/${id}`);
    dispatch({
      type: TOGGLE_LIKE,
      payload: { id, likes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Toggle Vote on Comment (on individual Post page which incl. comments)
export const toggleVote = (postId, commentId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/posts/comment/${postId}/vote/${commentId}`
    );
    dispatch({
      type: TOGGLE_VOTE,
      payload: { commentId, votes: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Toggle Report on a Post (on 'Posts' component)
export const addReport = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/posts/report/${id}`);
    dispatch({
      type: UPDATE_REPORTS,
      payload: { id, reports: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Toggle Report on Post (on individual Post page)
export const toggleReport = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/posts/report/${id}`);
    dispatch({
      type: TOGGLE_REPORT,
      payload: { id, reports: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Toggle Report on Comment (on individual Post page which incl. comments)
export const toggleCommentReport = (postId, commentId) => async (dispatch) => {
  try {
    const res = await axios.put(
      `/api/posts/comment/${postId}/report/${commentId}`
    );
    dispatch({
      type: TOGGLE_COMMENT_REPORT,
      payload: { commentId, reports: res.data },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Post
export const addPost = (formData) => async (dispatch) => {
  const config = {
    headers: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  try {
    const res = await axios.post('/api/posts/', formData, config);
    dispatch({
      type: ADD_POST,
      payload: res.data,
    });

    dispatch(setAlert('Post Added', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete Post
export const deletePost = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/posts/${id}`);
    dispatch({
      type: DELETE_POST,
      payload: id,
    });

    dispatch(setAlert('Post Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Comment
export const addComment = (postId, formData) => async (dispatch) => {
  const config = {
    headers: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  };

  try {
    const res = await axios.put(
      `/api/posts/comment/${postId}`,
      formData,
      config
    );
    dispatch({
      type: ADD_COMMENT,
      payload: res.data,
    });

    dispatch(setAlert('Response Added', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete Comment
export const deleteComment = (postId, commentId) => async (dispatch) => {
  try {
    await axios.delete(`/api/posts/comment/${postId}/${commentId}`);
    dispatch({
      type: DELETE_COMMENT,
      payload: commentId,
    });

    dispatch(setAlert('Response deleted', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
