import { TOGGLE_NAV } from '../actions/types';

const initialState = true;

const nav = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case TOGGLE_NAV:
      return !state;
    default:
      return state;
  }
};

export default nav;
