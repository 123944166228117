import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import Logo from '../layout/Logo';

const Navbar = ({ auth: { isAuthenticated, loading }, logout, nav }) => {
  const authLinks = (
    <ul>
      <li>
        <Link to='/posts'>
          <i className='fas fa-brain'></i>{' '}
          <span className='hide-sm'>Brainstorm</span>
        </Link>
      </li>
      <li>
        <Link to='/dashboard'>
          <i className='fas fa-tachometer-alt'></i>
          <span className='hide-sm'> Dashboard</span>
        </Link>
      </li>
      <li>
        <a href='#!' onClick={logout}>
          <i className='fas fa-sign-out-alt'></i>
          <span className='hide-sm'> Logout</span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to='/register'>Sign Up</Link>
      </li>
      <li>
        <Link to='/login'>Login</Link>
      </li>
    </ul>
  );

  return (
    <nav
      className={`navbar bg-white ${nav ? 'navFixed' : ''} ${
        !nav ? 'navTop' : ''
      }`}
    >
      <h1 className='company-name'>
        <Link to='/' className='comp-font'>
          <span className='hide-sm'>
            <Logo />
          </span>
          <span className='comp-color1'>plot</span>
          <span className='comp-color2'>producer</span>
        </Link>
      </h1>
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks} </Fragment>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  nav: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  nav: state.nav,
});

export default connect(mapStateToProps, { logout })(Navbar);
