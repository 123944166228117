import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import SideNav from '../layout/SideNav';
import { getCurrentOutline, clearCharacter } from '../../actions/outline';
import CharacterItem from './CharacterItem';

const CharacterList = ({
  getCurrentOutline,
  clearCharacter,
  outline: { outline, outlineLoading },
  history,
}) => {
  useEffect(() => {
    getCurrentOutline();
    clearCharacter();
  }, [getCurrentOutline, clearCharacter]);

  const { characters } = outline;

  return outlineLoading || outline === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <SideNav />
      <div className='text-centered'>
        <h1 className='large text-primary'>Characters</h1>
        <p className='lead'>
          View all of your characters in one place, click the "Full Info" button
          on one of your characters to view their full description, their custom
          notes/details, and to add more notes/details.
        </p>
        <Link to='/dashboard' className='btn btn-light btn-rounded my-1'>
          BACK
        </Link>
      </div>
      <div className='char-container'>
        {characters && characters !== null && characters.length > 0 ? (
          <div className='characters text-centered'>
            {characters.map((character, index) => (
              <CharacterItem
                key={index}
                character={character}
                history={history}
              />
            ))}
          </div>
        ) : (
          <Fragment>
            <div className='text-centered'>
              <h4>No characters found ...</h4>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

CharacterList.propTypes = {
  outline: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
});

export default connect(mapStateToProps, {
  getCurrentOutline,
  clearCharacter,
})(CharacterList);
