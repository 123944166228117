import React, { Fragment } from 'react';
import ppLogo from '../../img/SimplePenLogo.png';

const Logo = () => {
  return (
    <Fragment>
      <img
        src={ppLogo}
        style={{
          width: '50px',
          display: 'inline',
          padding: '0.25rem 0rem -0.5rem 0rem',
          margin: '0rem 0.5rem -0.75rem 0rem',
        }}
        alt='PlotProducer.com'
      />
    </Fragment>
  );
};

export default Logo;
