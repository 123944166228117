import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const ActNav = ({ outline }) => {
  const outlineProgress = (outln) => {
    if (outln) {
      const { characters, user, __v, _id, __proto__, ...answers } = outln;
      const numAnswered = Object.keys(answers).length;

      const totalQuestions = 33;

      return ((numAnswered / totalQuestions) * 100).toFixed(0);
    } else return 0;
  };

  const compPerc = outlineProgress(outline);

  return (
    <Fragment>
      <div className='act-area'>
        <Link
          to='/act-1'
          className='btn btn-act btn-act-1'
          onClick={(e) => {
            ReactGA.event({
              category: 'Outline',
              action: 'Navigated to Act 1 through the Act Nav component',
            });
          }}
        >
          <span className='act-text'>Act I</span>
        </Link>
        <Link
          to='/act-2'
          className='btn btn-act2'
          onClick={(e) => {
            ReactGA.event({
              category: 'Outline',
              action: 'Navigated to Act 2 through the Act Nav component',
            });
          }}
        >
          <span className='act-2-text'>Act II</span>
        </Link>
        <Link
          to='/act-3'
          className='btn btn-act my-1'
          onClick={(e) => {
            ReactGA.event({
              category: 'Outline',
              action: 'Navigated to Act 3 through the Act Nav component',
            });
          }}
        >
          <span className='act-text'>Act III</span>
        </Link>
      </div>
      <div className='outline-progress'>
        <div className='progress-light-grey progress-round '>
          <div
            className='progress-container progress-blue progress-round my-1'
            style={{ width: `${compPerc}%` }}
          >
            {compPerc}%
          </div>
          <div className='progress-exp-text hide-sm text-centered'>
            <small>Outline Progress</small>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ActNav;
