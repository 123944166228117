import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addNote } from '../../actions/outline';
import { toggleNav } from '../../actions/nav';
import ReactGA from 'react-ga';
import NoteSuggestions from './NoteSuggestions';

const AddNoteForm = ({ addNote, match, history, toggleNav }) => {
  const id = match.params.id.toString();

  const [formData, setFormData] = useState({
    noteTitle: '',
    noteText: '',
  });

  const { noteTitle, noteText } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Fragment>
      <div className='text-centered'>
        {' '}
        <Link
          to={`/outlines/characters/${id}`}
          className='btn btn-light btn-rounded my-1'
        >
          BACK
        </Link>
      </div>
      <div className='post-form'>
        <div className='post-form-header bg-primary text-centered'>
          <h3>Add Note</h3>
        </div>
        <form
          className='form my-1'
          onSubmit={(e) => {
            e.preventDefault();
            addNote({ id, formData, history });
            ReactGA.event({
              category: 'Character',
              action: 'Added Note to a Character',
            });
          }}
        >
          <div className='form-group'>
            <input
              type='text'
              placeholder='The title of your note. (Examples: Age, Height, Gender, Hair Color, etc.)'
              name='noteTitle'
              value={noteTitle}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
            />
          </div>
          <textarea
            cols='30'
            rows='10'
            placeholder='The content of your note. (Examples: 29, 6ft 3, Female, Brown, etc.)'
            name='noteText'
            value={noteText}
            onChange={(e) => onChange(e)}
            onFocus={(e) => toggleNav()}
            onBlur={(e) => toggleNav()}
          ></textarea>
          <div className='text-centered'>
            <input
              type='submit'
              value='Submit'
              className='btn btn-dark btn-rounded my-1'
            />
          </div>
        </form>
      </div>
      <NoteSuggestions match={match} />
    </Fragment>
  );
};

AddNoteForm.propTypes = {
  addNote: PropTypes.func.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

export default connect(null, { addNote, toggleNav })(AddNoteForm);
