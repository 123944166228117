import React, { Fragment } from 'react';
import Footer from '../../components/layout/Footer';

const Privacy = (props) => {
  return (
    <Fragment>
      <div className='text-centered main-container'>
        <h1 className='large text-primary'>Privacy Policy</h1>
        <br></br>
        <div className='text-left sml-fnt'>
          <br />
          <p>
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase
            from&nbsp;www.plotproducer.com&nbsp;(the &ldquo;Site&rdquo;).&nbsp;
          </p>
          <br />
          <p>
            <strong>PERSONAL INFORMATION WE COLLECT</strong>
          </p>
          <br />
          <p>
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically-collected information as &ldquo;Device
            Information.&rdquo;&nbsp;
          </p>
          <br />
          <p>
            We collect Device Information using the following
            technologies:&nbsp;
          </p>
          <br />
          <p>
            - &ldquo;Cookies&rdquo; are data files that are placed on your
            device or computer and often include an anonymous unique identifier.
            For more information about cookies, and how to disable cookies,
            visit&nbsp;http://www.allaboutcookies.org.&nbsp;
          </p>
          <p>
            - &ldquo;Log files&rdquo; track actions occurring on the Site, and
            collect data including your IP address, browser type, Internet
            service provider, referring/exit pages, and date/time stamps.&nbsp;
          </p>
          <p>
            - &ldquo;Web beacons,&rdquo; &ldquo;tags,&rdquo; and
            &ldquo;pixels&rdquo; are electronic files used to record information
            about how you browse the Site.&nbsp;
          </p>
          <br />
          <p>
            Additionally when you make a purchase or attempt to make a purchase
            through the Site, we collect certain information from you, including
            your name, billing address, shipping address, payment information
            (including credit card numbers), email address, and phone number. We
            refer to this information as &ldquo;Order Information.&rdquo;&nbsp;
          </p>
          <br />
          <p>
            When we talk about &ldquo;Personal Information&rdquo; in this
            Privacy Policy, we are talking both about Device Information and
            Order Information.&nbsp;
          </p>
          <br />
          <p>
            <strong>HOW DO WE USE YOUR PERSONAL INFORMATION?</strong>
          </p>
          <br />
          <p>
            We use the Order Information that we collect generally to fulfill
            any orders placed through the Site (including processing your
            payment information, arranging for shipping, and providing you with
            invoices and/or order confirmations). Additionally, we use this
            Order Information to:&nbsp;
          </p>
          <p>Communicate with you;&nbsp;</p>
          <p>Screen our orders for potential risk or fraud; and&nbsp;</p>
          <p>
            When in line with the preferences you have shared with us, provide
            you with information or advertising relating to our products or
            services.&nbsp;
          </p>
          <p>
            We use the Device Information that we collect to help us screen for
            potential risk and fraud (in particular, your IP address), and more
            generally to improve and optimize our Site (for example, by
            generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and
            advertising campaigns).&nbsp;
          </p>
          <br />
          <p>
            <strong>SHARING YOUR PERSONAL INFORMATION</strong>
          </p>
          <br />
          <p>
            We share your Personal Information with third parties to help us use
            your Personal Information, as described above.&nbsp;We also use
            Google Analytics to help us understand how our customers use the
            Site. We use cookies form third-party vendors such as Google for
            marketing purposes. These cookies allow us to show promotional
            materials to you when you are on other sites. We may also share
            information about your behavior on the Site with third parties
            (which includes operators of third-part websites or social
            networking platforms) in order to show your targeted advertisements
            and other content tailored for you.
          </p>
          <p>
            You can read more about how Google uses your Personal Information
            here:{' '}
            <a href='https://www.google.com/intl/en/policies/privacy/'>
              https://www.google.com/intl/en/policies/privacy/
            </a>
            .
          </p>
          <p>&nbsp;</p>
          <p>
            You can also opt-out of Google Analytics here:{' '}
            <a href='https://tools.google.com/dlpage/gaoptout'>
              https://tools.google.com/dlpage/gaoptout
            </a>
          </p>
          <br />
          <p>
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.&nbsp;
          </p>
          <br />
          <p>
            <strong>BEHAVIOURAL ADVERTISING</strong>
          </p>
          <br />
          <p>
            Third party vendors, including Google, use cookies to serve ads
            based on your prior visits to either out website or other websites.
            Google's use of advertising cookies enables it and its partners to
            serve ads to you (the user) based on your visit to our sites and/or
            other sites on the Internet.
            <br />
          </p>
          <br />
          <p>
            As described above, we use your Personal Information to provide you
            with targeted advertisements or marketing communications we believe
            may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising
            Initiative&rsquo;s (&ldquo;NAI&rdquo;) educational page at{' '}
            <a href='http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work'>
              http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
            </a>
            .
          </p>
          <br />
          <p>
            You can opt out of targeted advertising here:{' '}
            <a href='https://www.google.com/settings/ads'>
              https://www.google.com/settings/ads
            </a>
          </p>
          <br />
          <p>
            Additionally, you can opt out of some of these services by visiting
            the Digital Advertising Alliance&rsquo;s opt-out portal at:
            <a href='http://optout.aboutads.info/'>
              http://optout.aboutads.info/
            </a>
            .
          </p>
          <br />
          <p>
            <strong>DO NOT TRACK</strong>
          </p>
          <br />
          <p>
            Please note that we do not alter our Site&rsquo;s data collection
            and use practices when we see a Do Not Track signal from your
            browser.&nbsp;
          </p>
          <br />
          <p>
            <strong>YOUR RIGHTS</strong>
          </p>
          <br />
          <p>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.&nbsp;
          </p>
          <br />
          <p>
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.&nbsp;
          </p>
          <br />
          <p>
            <strong>DATA RETENTION</strong>
          </p>
          <br />
          <p>
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.&nbsp;
          </p>
          <br />
          <p>
            <strong>MINORS</strong>
          </p>
          <br />
          <p>
            The Site is not intended for individuals under the age of 13.&nbsp;
          </p>
          <br />
          <p>
            <strong>CHANGES</strong>
          </p>
          <br />
          <p>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.&nbsp;
          </p>
          <br />
          <p>
            <strong>CONTACT US</strong>
          </p>
          <br />
          <p>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e-mail at&nbsp;{' '}
            <a href='mailto:plotproducer@gmail.com'>plotproducer@gmail.com</a>.
          </p>
        </div>
        <div className='my-2'>
          {' '}
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Privacy;
