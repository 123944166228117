import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addWork } from '../../actions/profile';

const AddWork = ({ addWork, history }) => {
  const [formData, setFormData] = useState({
    title: '',
    author: '',
    genre: '',
    desc: '',
    asin: '',
    url: '',
  });

  const { title, author, genre, desc, asin, url } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <Fragment>
      <div className='text-centered'>
        <h1 className='large text-primary'>
          <p>
            <i className='fas fa-book'></i>{' '}
          </p>
          Add Finished Work
        </h1>
        <p className='lead'>
          Add a finished story (full novel, novella, script, or short story.)
        </p>
        <small>* = required field</small>
        <form
          className='form'
          onSubmit={(e) => {
            e.preventDefault();
            addWork(formData, history);
          }}
        >
          <div className='form-group'>
            <input
              type='text'
              placeholder='* Title'
              name='title'
              value={title}
              onChange={onChange}
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='* Author'
              name='author'
              value={author}
              onChange={onChange}
              required
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='* Genre'
              name='genre'
              value={genre}
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <textarea
              name='desc'
              value={desc}
              onChange={onChange}
              cols='30'
              rows='5'
              placeholder='* Description'
            ></textarea>
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='ASIN'
              name='asin'
              value={asin}
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              placeholder='Amazon URL / Other Purchase Link'
              name='url'
              value={url}
              onChange={onChange}
            />
          </div>
          <input type='submit' className='btn btn-primary my-1' />
          <Link to='/dashboard' className='btn btn-light my-1'>
            Go Back
          </Link>
        </form>
      </div>
    </Fragment>
  );
};

AddWork.propTypes = {
  addWork: PropTypes.func.isRequired,
};

export default connect(null, { addWork })(AddWork);
