import React, { useEffect, useState, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addCharacter, getCurrentOutline } from '../../actions/outline';
import { toggleNav } from '../../actions/nav';
import ReactGA from 'react-ga';

const initialState = {
  name: '',
  desc: '',
  photoUrl: '',
  protag: false,
};

const CreateCharacter = ({
  outline: { outline, outlineLoading },
  addCharacter,
  getCurrentOutline,
  history,
  toggleNav,
}) => {
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (!outline || Object.keys(outline).length === 0) getCurrentOutline();
    if (!outlineLoading && outline) {
      const outlineData = { ...initialState };
      for (const key in outline) {
        if (key in outlineData) outlineData[key] = outline[key];
      }
      setFormData(outlineData);
    }
  }, [outlineLoading, getCurrentOutline, outline]);

  const { name, desc, photoUrl, protag } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const protagSwitch = (e) => {
    setFormData({ ...formData, protag: !protag });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    addCharacter(formData, history, outline ? true : false);
  };

  const outlineProgress = (outln) => {
    if (outln) {
      const { characters, user, __v, _id, __proto__, ...answers } = outln;
      const numAnswered = Object.keys(answers).length;

      const totalQuestions = 33;

      return ((numAnswered / totalQuestions) * 100).toFixed(0);
    } else return 0;
  };

  const checkUrl = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  const compPerc = outlineProgress(outline);

  return (
    <Fragment>
      <section className='my-1'>
        <div className='text-centered'>
          {outline.title && <p className='large'>"{outline.title}"</p>}
        </div>
        <div className='progress-light-grey progress-round'>
          <div
            className='progress-container progress-blue progress-round my-1'
            style={{ width: `${compPerc}%` }}
          >
            {compPerc}%
          </div>
          <div className='progress-exp-text hide-sm text-centered'>
            <small>Outline Progress</small>
          </div>
        </div>

        <div className='text-centered my-2'>
          <h1 className='large text-primary'>Create a Character</h1>

          <p className='lead m-negtop1'>
            <i className='fas fa-user-plus'></i> Add a character
            <br />
          </p>
          <small>(Fields marked with an asterisk (*) are required)</small>
        </div>

        <form className='form' onSubmit={(e) => onSubmit(e)}>
          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>* NAME:</strong> What is this character's name?
              </small>
            </div>
            <input
              type='text'
              name='name'
              value={name}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              required
            />
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>* DESCRIPTION:</strong> Describe your character. You can
                write a brief summary or a longer form fleshed out backstory and
                arc explanation. Please keep in mind that you can edit this at
                any time and also add notes to your character after creation.
              </small>
            </div>
            <textarea
              name='desc'
              value={desc}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
              required
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>* CHARACTER PHOTO URL:</strong> Provide a link to a
                photo for this character. If the link is valid, you will see a
                preview of the link below.
              </small>
            </div>
            <input
              type='text'
              name='photoUrl'
              value={photoUrl}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              required
            />
          </div>
          {checkUrl(photoUrl) && (
            <div className='text-centered'>
              {/* eslint-disable-next-line */}
              <img
                className='char-photo-prev'
                src={photoUrl}
                alt='[PLEASE ENTER A VALID LINK TO A PHOTO]'
              />
            </div>
          )}

          <div className='form-group text-centered'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>PROTAGONIST:</strong> Is this character a protagonist of
                the story?
              </small>
            </div>
            <input
              type='checkbox'
              name='protag'
              value={protag}
              onChange={(e) => protagSwitch(e)}
            />{' '}
            Yes
          </div>

          <div className='text-centered'>
            <button
              type='submit'
              className='btn btn-primary my-1'
              onClick={(e) => {
                ReactGA.event({
                  category: 'Character',
                  action: 'Created a character',
                });
              }}
            >
              <i className='far fa-save'></i> Save
            </button>
          </div>

          <div className='text-centered'>
            <button type='submit' className='btn btn-primary btn-save-fixed'>
              <i className='far fa-save'></i> SAVE
            </button>
          </div>
        </form>
      </section>
    </Fragment>
  );
};

CreateCharacter.propTypes = {
  addCharacter: PropTypes.func.isRequired,
  getCurrentOutline: PropTypes.func.isRequired,
  outline: PropTypes.object.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
});

export default connect(mapStateToProps, {
  addCharacter,
  getCurrentOutline,
  toggleNav,
})(CreateCharacter);
