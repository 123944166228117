export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_OUTLINE = 'GET_OUTLINE';
export const GET_CHARACTER = 'GET_CHARACTER';
export const CLEAR_CHARACTER = 'CLEAR_CHARACTER';
export const UPDATE_OUTLINE = 'UPDATE_OUTLINE';
export const CLEAR_OUTLINE = 'CLEAR_OUTLINE';
export const OUTLINE_ERROR = 'OUTLINE_ERROR';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const TOGGLE_LIKE = 'TOGGLE_LIKE';
export const TOGGLE_VOTE = 'TOGGLE_VOTE';
export const TOGGLE_REPORT = 'TOGGLE_REPORT';
export const TOGGLE_COMMENT_REPORT = 'TOGGLE_COMMENT_REPORT';
export const UPDATE_REPORTS = 'UPDATE_REPORTS';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const TOGGLE_NAV = 'TOGGLE_NAV';
