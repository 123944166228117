import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteCharacter } from '../../actions/outline';
import ReactGA from 'react-ga';

const Characters = ({ characters, deleteCharacter, history }) => {
  const chars = characters.map((char) => (
    <tr key={char._id}>
      <td>
        <Link to={`/outlines/characters/${char._id}`}>
          <span className='char-name-link'>{char.name}</span>
        </Link>
      </td>

      {/* <td>
        <button className='btn btn-secondary sml-fnt btn-char-area'>
          <i className='fas fa-edit'></i>
          <small>
            <span className='hide-sm'> Edit</span>
          </small>
        </button>
      </td> */}
      <td>
        <Link to={`/outlines/characters/note/${char._id}`}>
          <button className='btn btn-primary sml-fnt btn-char-area'>
            <i className='fas fa-plus'></i>
            <small>
              <span className='hide-sm'> Add Info</span>
            </small>
          </button>
        </Link>
      </td>
      <td>
        <button
          onClick={() => {
            deleteCharacter(char._id, history);
            ReactGA.event({
              category: 'Character',
              action: 'Deleted character from dash (if accepted prompt)',
            });
          }}
          className='btn btn-danger sml-fnt btn-char-area'
        >
          <i className='fas fa-trash'></i>
          <small>
            <span className='hide-sm'> Delete</span>
          </small>
        </button>
      </td>
    </tr>
  ));

  if (chars.length !== 0) {
    return (
      <Fragment>
        <div className='py-3'>
          <h2>Character Quick View</h2>
          <table className='table table-alt'>
            <thead>
              <tr>
                <th>
                  <small>
                    <span className='sml-scrn-text'>Name</span>
                  </small>
                </th>
                {/* <th>
                  <small>
                    <span className='sml-scrn-text'>Edit</span>
                  </small>
                </th> */}
                <th>
                  <small>
                    <span className='sml-scrn-text'>Add Info</span>
                  </small>
                </th>
                <th>
                  <small>
                    <span className='sml-scrn-text'>Delete</span>
                  </small>
                </th>
              </tr>
            </thead>
            <tbody>{chars.length !== 0 && chars}</tbody>
          </table>
        </div>
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
};

Characters.propTypes = {
  characters: PropTypes.array.isRequired,
  deleteCharacter: PropTypes.func.isRequired,
};

export default connect(null, { deleteCharacter })(Characters);
