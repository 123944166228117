import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import PostItem from './PostItem';
import { getPosts } from '../../actions/post';
import PostForm from './PostForm';
import SideNav from '../layout/SideNav';
import braincloud from '../../img/voteicon.png';
import Footer from '../../components/layout/Footer';

const Posts = ({ getPosts, post: { posts, loading } }) => {
  useEffect(() => {
    getPosts();
  }, [getPosts]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <SideNav />
      <div className='text-centered main-container'>
        <h1 className='large text-primary'>Brainstorming</h1>
        <p className='lead'>
          Post your outline, log-line, or explain your plot idea to get
          feedback. Click 'discussion' to give feedback, and vote for a
          response/suggestion by hitting the "<strong>Brainstorm Button</strong>
          ".
        </p>
        <p>
          <img
            src={braincloud}
            alt='Brainstorm Button'
            className='vote-icon-img m-negtop1`'
          ></img>
        </p>
        <PostForm />
        <div className='posts-header'>
          <p>
            <strong>Brainstorm Posts</strong>
          </p>
        </div>
        <div className='posts'>
          {posts
            .filter((post) => post.reports.length < 5)
            .sort(function (a, b) {
              return b.likes.length - a.likes.length;
            })
            .map((post) => (
              <PostItem key={post._id} post={post} />
            ))}
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPosts })(Posts);
