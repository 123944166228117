import {
  GET_OUTLINE,
  UPDATE_OUTLINE,
  OUTLINE_ERROR,
  CLEAR_OUTLINE,
  GET_CHARACTER,
  CLEAR_CHARACTER,
} from '../actions/types';

const initialState = { outline: {}, outlineLoading: true, error: {} };

const outline = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_OUTLINE:
    case UPDATE_OUTLINE:
      return {
        ...state,
        outline: payload,
        outlineLoading: false,
      };

    case OUTLINE_ERROR:
      return {
        ...state,
        error: payload,
        outline: null,
        outlineLoading: false,
      };
    case CLEAR_OUTLINE:
      return {
        ...state,
        outline: null,
        outlineLoading: false,
      };
    case GET_CHARACTER:
      return {
        ...state,
        character: payload,
        outlineLoading: false,
      };
    case CLEAR_CHARACTER:
      return {
        ...state,
        character: null,
        outlineLoading: false,
      };
    default:
      return state;
  }
};

export default outline;
