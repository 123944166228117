import React, { useEffect, useState, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createOutline, getCurrentOutline } from '../../actions/outline';
import { toggleNav } from '../../actions/nav';
import OutlineTools from './OutlineTools';
import ActNav from './ActNav';
import SideNav from '../layout/SideNav';
import questions from './questions.json';
import shortenText from '../../utils/shortenText';
import ReactGA from 'react-ga';

const initialState = {
  title: '',
  act1a01: '',
  act1a02: '',
  act1a03: '',
  act1a04: '',
  act1a05: '',
  act1a06: '',
  act1a07: '',
  act1a08: '',
};

const questionState = {
  a01q01: true,
  a01q02: true,
  a01q03: true,
  a01q04: true,
  a01q05: true,
  a01q06: true,
  a01q07: true,
  a01q08: true,
};

const ActOne = ({
  outline: { outline, outlineLoading },
  createOutline,
  getCurrentOutline,
  history,
  toggleNav,
}) => {
  const [formData, setFormData] = useState(initialState);
  const [questionData, setQuestionData] = useState(questionState);

  useEffect(() => {
    if (!outline || Object.keys(outline).length === 0) getCurrentOutline();
    if (!outlineLoading && outline) {
      const outlineData = { ...initialState };
      for (const key in outline) {
        if (key in outlineData) outlineData[key] = outline[key];
      }
      setFormData(outlineData);
    }
  }, [outlineLoading, getCurrentOutline, outline]);

  const {
    title,
    act1a01,
    act1a02,
    act1a03,
    act1a04,
    act1a05,
    act1a06,
    act1a07,
    act1a08,
  } = formData;

  const { a01q01, a01q02, a01q03, a01q04, a01q05, a01q06, a01q07, a01q08 } =
    questionData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createOutline(formData, history, outline ? true : false);
  };

  const showMore = (e, q, qstring) => {
    e.preventDefault();
    setQuestionData({
      ...questionData,
      [qstring]: !q,
    });
  };

  return (
    <Fragment>
      <SideNav />
      <div className='text-centered'>
        {title && <p className='large'>"{title}"</p>}
        <ActNav outline={outline} />
      </div>
      <section>
        <div className='text-centered my-2'>
          <h1 className='large text-primary'>Getting Started</h1>

          <p className='lead'>
            <i className='fas fa-scroll'></i> Act I<br />
          </p>
          <p>The Setup</p>

          <small>
            (Each question can be expanded for more information, as well as
            examples from famous works.)
          </small>
        </div>

        <form className='form' onSubmit={(e) => onSubmit(e)}>
          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong> TITLE: </strong> - What is the name of your story?
                <span className='required-notice'> *Required </span>
              </small>
            </div>
            <input
              type='text'
              name='title'
              value={title}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              required
            />
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>1:</strong>{' '}
                {questionData.a01q01 === true ? (
                  <span>{shortenText(questions.a01q01, 125)}</span>
                ) : (
                  <span>{questions.a01q01}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => {
                      showMore(e, a01q01, 'a01q01');
                    }}
                    onFocus={(e) => toggleNav()}
                  >
                    {questionData.a01q01 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a01'
              value={act1a01}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>2:</strong>{' '}
                {questionData.a01q02 === true ? (
                  <span>{shortenText(questions.a01q02, 125)}</span>
                ) : (
                  <span>{questions.a01q02}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a01q02, 'a01q02')}
                  >
                    {questionData.a01q02 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a02'
              value={act1a02}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>3:</strong>{' '}
                {questionData.a01q03 === true ? (
                  <span>{shortenText(questions.a01q03, 125)}</span>
                ) : (
                  <span>{questions.a01q03}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a01q03, 'a01q03')}
                  >
                    {questionData.a01q03 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a03'
              value={act1a03}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>4: THE HOOK - </strong>{' '}
                {questionData.a01q04 === true ? (
                  <span>{shortenText(questions.a01q04, 125)}</span>
                ) : (
                  <span>{questions.a01q04}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a01q04, 'a01q04')}
                  >
                    {questionData.a01q04 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a04'
              value={act1a04}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>5:</strong>{' '}
                {questionData.a01q05 === true ? (
                  <span>{shortenText(questions.a01q05, 125)}</span>
                ) : (
                  <span>{questions.a01q05}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a01q05, 'a01q05')}
                  >
                    {questionData.a01q05 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a05'
              value={act1a05}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>6:</strong>{' '}
                {questionData.a01q06 === true ? (
                  <span>{shortenText(questions.a01q06, 125)}</span>
                ) : (
                  <span>{questions.a01q06}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a01q06, 'a01q06')}
                  >
                    {questionData.a01q06 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a06'
              value={act1a06}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>7:</strong>{' '}
                {questionData.a01q07 === true ? (
                  <span>{shortenText(questions.a01q07, 125)}</span>
                ) : (
                  <span>{questions.a01q07}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a01q07, 'a01q07')}
                  >
                    {questionData.a01q07 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a07'
              value={act1a07}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='form-group'>
            <div className='question-info'>
              <small className='form-text'>
                <strong>
                  8: THE INCITING INCIDENT / THE 1ST PLOT POINT -{' '}
                </strong>
                {questionData.a01q08 === true ? (
                  <span>{shortenText(questions.a01q08, 100)}</span>
                ) : (
                  <span>{questions.a01q08}</span>
                )}
              </small>
              <div className='info-caret'>
                <div className='infocareticon'>
                  <button
                    className='btn btn-rounded btn-more'
                    onClick={(e) => showMore(e, a01q08, 'a01q08')}
                  >
                    {questionData.a01q08 === true ? (
                      <span>
                        <i className='fas fa-caret-down'></i>
                      </span>
                    ) : (
                      <span>
                        <i className='fas fa-caret-up'></i>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <textarea
              name='act1a08'
              value={act1a08}
              onChange={(e) => onChange(e)}
              onFocus={(e) => toggleNav()}
              onBlur={(e) => toggleNav()}
              cols='30'
              rows='5'
            ></textarea>
          </div>

          <div className='text-centered'>
            <button
              type='submit'
              className='btn btn-primary my-1'
              onClick={(e) => {
                ReactGA.event({
                  category: 'Outline',
                  action: 'Saved outline from bottom-button (act 1)',
                });
              }}
            >
              <i className='far fa-save'></i> SAVE
            </button>
          </div>

          <OutlineTools />
        </form>
      </section>
    </Fragment>
  );
};

ActOne.propTypes = {
  createOutline: PropTypes.func.isRequired,
  getCurrentOutline: PropTypes.func.isRequired,
  outline: PropTypes.object.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
});

export default connect(mapStateToProps, {
  createOutline,
  getCurrentOutline,
  toggleNav,
})(ActOne);
