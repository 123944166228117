import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../actions/post';
import { toggleNav } from '../../actions/nav';
import ReactGA from 'react-ga';

const CommentForm = ({ addComment, postId, toggleNav, auth }) => {
  const [text, setText] = useState('');

  return (
    <div className='post-form text-centered'>
      <div className='post-form-header bg-primary m-top3'>
        <h3>Post your response or suggestions ...</h3>
        <span className='sml-fnt'>
          (Please make sure your posts & comments adhere to the{' '}
          <Link to='/guidelines'>Community Guidelines</Link>)
        </span>
      </div>
      <form
        className='form my-1'
        onSubmit={(e) => {
          e.preventDefault();
          addComment(postId, { text });
          setText('');
          ReactGA.event({
            category: 'Brainstorm',
            action: 'Commented on a Brainstorm Post',
          });
        }}
      >
        <textarea
          cols='30'
          rows='10'
          placeholder='Your response / suggestions ...'
          value={text}
          onChange={(e) => setText(e.target.value)}
          onFocus={(e) => toggleNav()}
          onBlur={(e) => toggleNav()}
        ></textarea>
        {!auth.loading && auth.user.emailVerified ? (
          <input
            type='submit'
            value='Submit'
            className='btn btn-dark btn-rounded my-1'
          />
        ) : (
          <Fragment>
            <div className='text-centered verify-container'>
              <strong>
                Please verify your email address in order to submit comments
              </strong>
              <Link to='/resend'>
                <br />
                <button className=' btn verify-notice'>Verify Email</button>
              </Link>
            </div>
          </Fragment>
        )}
      </form>
    </div>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addComment, toggleNav })(CommentForm);
