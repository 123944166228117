import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addPost } from '../../actions/post';
import { toggleNav } from '../../actions/nav';
import ReactGA from 'react-ga';

const PostForm = ({ addPost, toggleNav, auth }) => {
  const [text, setText] = useState('');

  if (!auth.loading && auth.user.emailVerified === true) {
    return (
      <div className='post-form'>
        <div className='post-form-header bg-primary'>
          <h3>Post your plot idea ...</h3>

          <span className='sml-fnt'>
            (Please make sure your posts & comments adhere to the{' '}
            <Link to='/guidelines'>Community Guidelines</Link>)
          </span>
        </div>
        <form
          className='form my-1'
          onSubmit={(e) => {
            e.preventDefault();
            addPost({ text });
            setText('');
            ReactGA.event({
              category: 'Brainstorm',
              action: 'Created a post',
            });
          }}
        >
          <textarea
            cols='30'
            rows='10'
            placeholder='(Copy and paste your outline, or simply explain your plot idea ...)'
            value={text}
            onChange={(e) => setText(e.target.value)}
            onFocus={(e) => toggleNav()}
            onBlur={(e) => toggleNav()}
          ></textarea>
          <br />
          <input
            type='submit'
            value='Submit'
            className='btn btn-dark btn-rounded my-1'
          />
        </form>
      </div>
    );
  } else {
    return (
      <Fragment>
        <div className='text-centered verify-container'>
          <strong>
            Please verify your email address in order to create posts in the
            brainstorm area.
          </strong>
          <Link to='/resend'>
            <br />
            <button className=' btn verify-notice my-1'>Verify Email</button>
          </Link>
        </div>
      </Fragment>
    );
  }
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired,
  toggleNav: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addPost, toggleNav })(PostForm);
