import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentOutline } from '../../actions/outline';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dismissPrompt } from '../../actions/outline';
import ReactGA from 'react-ga';

const StoryArea = ({
  outline: { outline, outlineLoading },
  dismissPrompt,
  history,
}) => {
  const [ifPromptDismissed, setIfPromptDismissed] = useState(outline);

  const { promptDismissed } = ifPromptDismissed;

  const { title } = outline;

  const outlineProgress = (outln) => {
    const { characters, user, __v, _id, __proto__, ...answers } = outln;

    const numAnswered = Object.keys(answers).length;

    const totalQuestions = 33;

    return ((numAnswered / totalQuestions) * 100).toFixed(0);
  };

  const compPerc = outlineProgress(outline);

  const promptSwitch = () => {
    const dismissed = { promptDismissed: true };
    setIfPromptDismissed({
      promptDismissed: !promptDismissed,
    });
    dismissPrompt(dismissed, history);
  };

  return (
    <div className='story-area'>
      <div
        className={`story-area-prompt ${
          !promptDismissed ? '' : 'prompt-hidden'
        }`}
      >
        <form className='form'>
          {!promptDismissed && (
            <div className='prompt-close hide-sm'>
              <button className='close-prompt-x'>
                <i
                  className='fas fa-times'
                  onClick={(e) => {
                    promptSwitch(e);
                    ReactGA.event({
                      category: 'Outline',
                      action: 'closed tutorial prompt',
                    });
                  }}
                ></i>
              </button>
              <span className='close-prompt-text'>
                (CLOSE REMINDER PERMANENTLY)
              </span>
            </div>
          )}
        </form>

        <p className='large my-2'>"{title}"</p>
        <div className='act-area'>
          <Link to='/act-1' className='btn btn-act btn-act-1'>
            <span className='act-text'>Act I</span>
          </Link>
          <Link to='/act-2' className='btn btn-act2'>
            <span className='act-2-text'>Act II</span>
          </Link>
          <Link to='/act-3' className='btn btn-act my-1'>
            <span className='act-text'>Act III</span>
          </Link>
        </div>
        <div className='outline-progress'>
          <div className='progress-light-grey progress-round'>
            <div
              className='progress-container progress-blue progress-round my-1'
              style={{ width: `${compPerc}%` }}
            >
              <span className='compl-perc-text'>
                <strong>{compPerc}%</strong>
              </span>
            </div>
          </div>
          <div className='progress-exp-text hide-sm'>
            <small>Outline Progress</small>
          </div>
        </div>
      </div>
    </div>
  );
};

StoryArea.propTypes = {
  outline: PropTypes.object.isRequired,
  dismissPrompt: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
});

export default connect(mapStateToProps, { dismissPrompt, getCurrentOutline })(
  StoryArea
);
