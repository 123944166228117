import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

const SideNav = () => {
  const [collapsed, setCollapsed] = useState(true);

  const handleToggle = () => {
    setCollapsed(!collapsed);
    console.log(collapsed);
  };

  let navClass = 'side-nav side-nav--expanded side-nav--collapsed';
  let toolTipText = '';

  if (collapsed === false) {
    navClass = 'side-nav side-nav--expanded';
    toolTipText = 'Minimize';
  }

  if (collapsed === true) {
    navClass = 'side-nav side-nav--expanded side-nav--collapsed';
    toolTipText = 'Expand';
  }

  return (
    <Fragment>
      <div className={navClass}>
        <div
          className='side-nav__controls-container tooltip'
          onClick={handleToggle}
        >
          <div className='side-nav__max-btn'>
            <i className='fas fa-expand-alt'></i>
            <span className='tooltiptext'>
              <small>{toolTipText}</small>
            </span>
          </div>
          <div className='side-nav__min-btn'>
            <i className='fas fa-compress-alt'></i>
            <span className='tooltiptext'>
              <small>{toolTipText}</small>
            </span>
          </div>
          <div className='side-nav__close-btn'>
            <i className='fas fa-times'></i>
          </div>
        </div>

        <div className='side-nav__container'>
          <div className='side-nav__icon-container'>
            <div className='navicon'>
              <Link to='/act-1'>
                <span className='side-nav__act-number'>I</span>
              </Link>
            </div>

            <div className='navicon'>
              <Link to='/act-2'>
                <span className='side-nav__act-number'>II</span>
              </Link>
            </div>
            <div className='navicon'>
              <Link to='/act-3'>
                <span className='side-nav__act-number'>III</span>
              </Link>
            </div>
            <div className='navicon'>
              <Link to='/outlineoutput'>
                <span className='side-nav__icon'>
                  <i className='fas fa-eye'></i>
                </span>
              </Link>
            </div>
            <div className='navicon'>
              <Link to='/create-character'>
                <span className='side-nav__icon'>
                  <i className='fas fa-user-plus'></i>
                </span>
              </Link>
            </div>
            <div className='navicon'>
              <Link to='/outlines/characters/me'>
                <span className='side-nav__icon'>
                  <i className='fas fa-users'></i>
                </span>
              </Link>
            </div>

            <div className='navicon'>
              <Link to='/posts'>
                <span className='side-nav__icon'>
                  <i className='fas fa-brain'></i>
                </span>
              </Link>
            </div>
            <div className='navicon'>
              <Link to='/dashboard'>
                <span className='side-nav__icon'>
                  <i className='fas fa-tachometer-alt'></i>
                </span>
              </Link>
            </div>
            {/* <div className='navicon'>
              <Link to='/'>
                <span className='side-nav__icon'>
                  <i className='fas fa-sign-out-alt'></i>
                </span>
              </Link>
            </div> */}
          </div>

          <div className='side-nav__item-text-container'>
            <div>
              <Link to='/act-1'>
                <span className='side-nav__item-text'> Act I</span>
              </Link>
            </div>
            <div>
              <Link to='/act-2'>
                <span className='side-nav__item-text'> Act II</span>
              </Link>
            </div>
            <div>
              <Link to='/act-3'>
                <span className='side-nav__item-text'>Act III</span>
              </Link>
            </div>
            <div>
              <Link to='/outlineoutput'>
                <span className='side-nav__item-text'> View Outline</span>
              </Link>
            </div>
            <div>
              <Link to='/create-character'>
                <span className='side-nav__item-text'> Create Character</span>
              </Link>
            </div>
            <div>
              <Link to='/outlines/characters/me'>
                <span className='side-nav__item-text'> View Characters</span>
              </Link>
            </div>

            <div>
              <Link to='/posts'>
                <span className='side-nav__item-text'> Brainstorming </span>
              </Link>
            </div>
            <div>
              <Link to='/dashboard'>
                <span className='side-nav__item-text'> Dashboard</span>
              </Link>
            </div>
            {/* <div>
              <Link to='/'>
                <span className='side-nav__item-text'> Log Out</span>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideNav;
