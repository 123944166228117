import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const OutlineTools = () => {
  return (
    <Fragment>
      <button
        type='submit'
        className='btn btn-primary btn-save-fixed'
        onClick={(e) => {
          ReactGA.event({
            category: 'Outline',
            action: 'Saved outline from side-button (fixed position)',
          });
        }}
      >
        <i className='far fa-save'></i> SAVE
      </button>

      <Link to='/dashboard' className='btn btn-back-fixed'>
        <i className='fas fa-arrow-left'></i> BACK
      </Link>
    </Fragment>
  );
};

export default OutlineTools;
