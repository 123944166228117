import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { verifyEmail } from '../../actions/auth';
// import Footer from '../../components/layout/Footer';

const Verify = ({ match, verifyEmail, history }) => {
  const token = match.params.token;

  return (
    <Fragment>
      <div className='text-centered'>
        <strong>Verify your email address:</strong>
        <br />
        <br />
        Click the button below to verify your account and unlock the ability to:
        <br />
        <br />
        <div className='advantages'>
          {' '}
          <ul>
            <li>- Upload a Profile Image</li>
            <li>- Create Posts in the Brainstorm Area</li>
            <li>- Comment on Brainstorm Posts</li>
            <li>- Like Posts</li>
            <li>- Vote for Comments</li>
          </ul>
        </div>
        <br />
        <p>
          <button
            className='btn btn-primary btn-rounded large'
            onClick={(e) => {
              verifyEmail(token, history);
            }}
          >
            VERIFY ACCOUNT
          </button>
          <br />
        </p>
      </div>
    </Fragment>
  );
};

export default connect(null, { verifyEmail })(Verify);
