import React, { Fragment, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getCurrentOutline, getCharacterById } from '../../actions/outline';
import CharacterItemFullInfo from './CharacterItemFullInfo';

const CharacterFullInfo = ({ match, getCharacterById, outline, history }) => {
  useEffect(() => {
    getCharacterById(match.params.id);
  }, [getCharacterById, match.params.id]);

  const { character } = outline;

  return !character || outline.outlineLoading !== false ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className='text-centered char-card-full'>
        {character && character !== null ? (
          <CharacterItemFullInfo character={character} history={history} />
        ) : (
          <p> Character Not Found</p>
        )}
      </div>
    </Fragment>
  );
};

CharacterFullInfo.propTypes = {
  outline: PropTypes.object.isRequired,
  getCharacterById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  outline: state.outline,
  character: state.character,
});

export default connect(mapStateToProps, {
  getCurrentOutline,
  getCharacterById,
})(CharacterFullInfo);
