import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { resendVerify } from '../../actions/auth';
// import Footer from '../../components/layout/Footer';

const ResendVerify = ({ match, resendVerify, history }) => {
  return (
    <Fragment>
      <div className='text-centered'>
        <strong>Resend Your Verification Email</strong>
        <br />
        <br />
        Click the button below to re-send your verification email. The emial
        should arrive right away, but please allow up to 2 hours for delivery.
        <br />
        <br />
        <strong>Once activated you can:</strong>
        <br />
        <div className='advantages'>
          {' '}
          <ul>
            <li>- Upload a Profile Image</li>
            <li>- Create Posts in the Brainstorm Area</li>
            <li>- Comment on Brainstorm Posts</li>
            <li>- Like Posts</li>
            <li>- Vote for Comments</li>
          </ul>
        </div>
        <br />
        <p>
          <button
            className='btn btn-primary btn-rounded large'
            onClick={(e) => {
              resendVerify(history);
            }}
          >
            RESEND VERIFICATION EMAIL
          </button>
          <br />
        </p>
      </div>
    </Fragment>
  );
};

export default connect(null, { resendVerify })(ResendVerify);
