import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { toggleLike, deletePost, toggleReport } from '../../actions/post';
import ReactGA from 'react-ga';

const SinglePostItem = ({
  toggleLike,
  toggleReport,
  post: { likes, _id, text, name, avatar, user, comments, date, reports },
}) => {
  return (
    <Fragment>
      <div className='text-centered my-2'>
        <Link to='/posts' className='btn btn-light'>
          BACK
        </Link>
      </div>
      <div className='post bg-white my-1 p-1'>
        <div>
          <Link to={`/profile/${user}`}>
            <img className='round-img' src={avatar} alt='Avatar' />
            <h4>{name}</h4>
          </Link>
        </div>

        <div className='post-buttons'>
          <div className='post-text pre-wrap'>
            <p className='my-1'>{text}</p>
          </div>
          <div className='post-date sml-fnt'>
            <div>
              Posted on <Moment format='MMMM Do, YYYY - h:mm a'>{date}</Moment>
            </div>
          </div>
          <div className='post-like-full'>
            <button
              className='btn btn-light btn-rounded'
              onClick={(e) => {
                toggleLike(_id);
                ReactGA.event({
                  category: 'Brainstorm',
                  action: 'Changed like on a Brainstorm post',
                });
              }}
            >
              <i className='fas fa-star'></i>
              {likes && likes.length > 0 && (
                <span key={likes}> {likes.length}</span>
              )}
            </button>
          </div>
          <div className='post-report'>
            <button
              type='button'
              className='btn report-btn'
              onClick={(e) => {
                toggleReport(_id);
              }}
            >
              <i className='fas fa-flag'></i>
            </button>
            <span className='report-text'>
              Report this post for violating our guidelines{' '}
              {reports && reports.length > 0 && (
                <small>{reports.length} reports</small>
              )}
            </span>{' '}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SinglePostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  toggleLike: PropTypes.func.isRequired,
  toggleReport: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  toggleLike,
  deletePost,
  toggleReport,
})(SinglePostItem);
