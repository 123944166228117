import React from 'react';
import PropTypes from 'prop-types';

const ProfileWorks = ({ works: { title, author, genre, desc, asin, url } }) => (
  <div>
    <h3 className='text-dark'>"{title}"</h3>
    <small>by {author}</small>
    <p>
      <strong>Genre: </strong>
      {genre}
    </p>
    <p>
      <strong>Description: </strong> {desc}
    </p>
    {asin && (
      <p>
        <strong>ASIN:</strong> {asin}
      </p>
    )}
    {url && (
      <p>
        <a href={url}>
          <button className='btn btn-primary'>Purchase</button>
        </a>
      </p>
    )}
  </div>
);

ProfileWorks.propTypes = {
  works: PropTypes.array.isRequired,
};

export default ProfileWorks;
