import axios from 'axios';
import { setAlert } from './alert';

import {
  GET_OUTLINE,
  UPDATE_OUTLINE,
  OUTLINE_ERROR,
  GET_CHARACTER,
  CLEAR_CHARACTER,
} from './types';

// Get current user's outline
export const getCurrentOutline = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/outlines/me');

    dispatch({
      type: GET_OUTLINE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: OUTLINE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Create or Update outline
export const createOutline =
  (formData, history, edit = false) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('/api/outlines', formData, config);

      dispatch({
        type: GET_OUTLINE,
        payload: res.data,
      });

      dispatch(
        setAlert(edit ? 'Outline Updated' : 'Outline Created', 'success')
      );

      if (!edit) {
        history.push('/dashboard');
      }
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: OUTLINE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Add Character
export const addCharacter = (formData, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.put('/api/outlines/characters', formData, config);

    dispatch({
      type: UPDATE_OUTLINE,
      payload: res.data,
    });

    dispatch(setAlert('Character Added', 'success'));

    history.push('/dashboard');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OUTLINE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete Character
export const deleteCharacter = (id, history) => async (dispatch) => {
  if (
    window.confirm(
      'Are you sure that you want to delete this character? This is permanent and can NOT be undone.'
    )
  ) {
    try {
      const res = await axios.delete(`/api/outlines/characters/${id}`);

      dispatch({
        type: UPDATE_OUTLINE,
        payload: res.data,
      });

      dispatch(setAlert('Character Deleted', 'success'));

      if (history) {
        history.push('/dashboard');
      }
    } catch (err) {
      dispatch({
        type: OUTLINE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  }
};

// Get all of a User's Characters from their Outline
export const getCharacters = (id) => async (dispatch) => {
  dispatch({ type: GET_OUTLINE });

  try {
    const res = await axios.get(`/characters/:${id}`);

    dispatch({
      type: GET_OUTLINE,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: OUTLINE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Get a User's Character by ID
export const getCharacterById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/outlines/characters/${id}`);

    dispatch({
      type: GET_CHARACTER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: OUTLINE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Clear Character
export const clearCharacter = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_CHARACTER });
  } catch (err) {
    dispatch({
      type: OUTLINE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add Note
export const addNote =
  ({ id, formData, history }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.put(
        `/api/outlines/characters/note/${id}`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_OUTLINE,
        payload: res.data,
      });

      dispatch(setAlert('Note added to character', 'success'));

      history.push(`/outlines/characters/${id}`);
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }

      dispatch({
        type: OUTLINE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
  };

// Delete Note
export const deleteNote = (char_id, note_id, history) => async (dispatch) => {
  try {
    const res = await axios.delete(
      `/api/outlines/characters/note/${char_id}/${note_id}`
    );

    dispatch({
      type: UPDATE_OUTLINE,
      payload: res.data,
    });

    dispatch(setAlert('Note Deleted', 'success'));

    if (history) {
      history.push('/outlines/characters/me');
    }
  } catch (err) {
    dispatch({
      type: OUTLINE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Dismiss Story Area Reminder Prompt (Act Prompt)
export const dismissPrompt = (dismissed) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post('/api/outlines', dismissed, config);

    dispatch({
      type: GET_OUTLINE,
      payload: res.data,
    });
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: OUTLINE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
