import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import DashboardActions from './DashboardActions';
import FinishedWorks from './FinishedWorks';
import MobileNav from '../layout/MobileNav';
import SideNav from '../layout/SideNav';
import StoryArea from './StoryArea';
// import CreateViewCharArea from './CreateViewCharArea';
import Characters from './Characters';
import StoryStartPrompt from './StoryStartPrompt';
import { getCurrentProfile, deleteUser } from '../../actions/profile';
import { getCurrentOutline } from '../../actions/outline';
import Footer from '../../components/layout/Footer';

const Dashboard = ({
  getCurrentProfile,
  getCurrentOutline,
  deleteUser,
  auth: { user },
  profile: { profile, loading },
  outline: { outline, outlineLoading },
}) => {
  useEffect(() => {
    getCurrentProfile();
    getCurrentOutline();
  }, [getCurrentProfile, getCurrentOutline]);

  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <SideNav />
      <div className='text-centered main-container'>
        <h1 className='large text-primary'>
          <p>
            <i className='fas fa-tachometer-alt'></i>
          </p>
          Dashboard
        </h1>
        <p className='lead m-negtop1'>Welcome {user && user.name}</p>
        {outline !== null && outline.title ? (
          <Fragment>
            <MobileNav outline={outline} />
            <StoryArea outline={outline} />
            <Characters characters={outline.characters} />
            {/* <CreateViewCharArea characters={outline.characters} /> */}
          </Fragment>
        ) : (
          <StoryStartPrompt />
        )}

        {profile !== null ? (
          <Fragment>
            {profile.works.length > 0 && (
              <FinishedWorks works={profile.works} />
            )}
            <DashboardActions />

            <div className='my-2'>
              <button className='btn btn-danger' onClick={() => deleteUser()}>
                <i className='fas fa-user-slash'></i> Delete My Account
              </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <p className='m-top2'>
              You have not yet set up a profile. Please fill out your profile
              info.
            </p>
            <p>
              <small>
                (Your profile will be public, and creating one is{' '}
                <strong>entirely optional</strong>.)
              </small>
            </p>
            <Link to='create-profile' className='btn btn-primary'>
              Create Profile
            </Link>
          </Fragment>
        )}
        <div className='m-top5'>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getCurrentOutline: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  outline: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  outline: state.outline,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getCurrentOutline,
  deleteUser,
})(Dashboard);
