import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import {
  deleteComment,
  toggleVote,
  toggleCommentReport,
} from '../../actions/post';
import braincloud from '../../img/voteicon.png';
import ReactGA from 'react-ga';

const CommentItem = ({
  postId,
  comment: { _id, text, name, avatar, user, date, votes, reports },
  auth,
  deleteComment,
  toggleVote,
  toggleCommentReport,
}) => {
  return (
    <div className='comment bg-white p-1 my-1 text-centered'>
      <div>
        <Link to={`/profile/${user}`}>
          <img className='round-img' src={avatar} alt={name} />
          <h4>{name}</h4>
        </Link>
      </div>
      <div>
        {reports.length < 5 ? (
          <p className='my-1 pre-wrap'>{text}</p>
        ) : (
          <p className='my-1 text-light'>
            <small>
              [This comment has been flagged by the community and is awaiting
              moderation]
            </small>
          </p>
        )}
        <p className='post-date'>
          <small>
            {' '}
            Posted on <Moment format='h:mm a on MMMM Do, YYYY'>{date}</Moment>
          </small>
        </p>
        <div className='comment-vote'>
          <div className='vote-btn-container'>
            <button
              className='btn btn-light vote-btn'
              onClick={(e) => {
                toggleVote(postId, _id);
                ReactGA.event({
                  category: 'Brainstorm',
                  action: 'Changed vote on a Brainstorm response/comment',
                });
              }}
            >
              {/* <i className='fas fa-cloud'></i> */}
              <img src={braincloud} alt='' className='vote-icon'></img>
              {votes && votes.length > 0 && (
                <span className='vote-count'> {votes.length}</span>
              )}
            </button>
          </div>
        </div>
        {!auth.loading &&
          (user === auth.user._id ||
            auth.user.role === 'admin' ||
            auth.user.role === 'mod') && (
            <button
              type='button'
              className='btn btn-trash-red'
              onClick={(e) => deleteComment(postId, _id)}
            >
              <i className='fas fa-trash'></i>
            </button>
          )}
      </div>
      <div className='comment-report'>
        <button
          type='button'
          className='btn comment-report-btn'
          onClick={(e) => {
            toggleCommentReport(postId, _id);
          }}
        >
          <i className='fas fa-flag'></i>
        </button>
        <span className='comment-report-text'>
          Report this response for violating our guidelines{' '}
          {reports && reports.length > 0 && (
            <small>{reports.length} reports</small>
          )}
        </span>{' '}
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
  toggleVote: PropTypes.func.isRequired,
  toggleCommentReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteComment,
  toggleVote,
  toggleCommentReport,
})(CommentItem);
