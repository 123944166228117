import React, { Fragment } from 'react';
import Footer from '../../components/layout/Footer';

const Guidelines = (props) => {
  return (
    <Fragment>
      <div className='text-centered main-container'>
        <h1 className='large text-primary'>Community & Content Guidelines</h1>
        <br></br>
        <div className='text-left sml-fnt'>
          <p>&nbsp;&nbsp;</p>
          <p className='text-centered'>
            <strong>
              The rules below listed are in regard to the content of posts,
              comments, etc. made here on Plot Producer.
            </strong>
            <br />
            <br />
            (These rules may change or be added to at any time.)
          </p>
          <p>
            <br />
            <strong>Rule 1</strong>
          </p>
          <p>
            Please use common sense and treat others with respect.
            <br />
            <br />
            No posts, comments, profiles, or other types of community content is
            to contain any racism, sexism, harassment, bullying, hate-speech or
            threats of violence. This type of content is unnacceptable and can
            lead to being banned, having your account removed, etc.
          </p>
          <p>
            <br />
            <strong>Rule 2</strong>
          </p>
          <p>
            No spamming, vote manipulation, ban evasion, or other activities
            that interfere with the intended use of Plot Producer.
          </p>
          <p>
            <br />
            <strong>Rule 3</strong>
          </p>
          <p>
            No blatant promoting or advertising of goods, services, or websites.
            Books and other finished works may be promoted through your profile.
            Your website and social media can also be linked to through your
            profile. However, posting affiliate marketing links or any links
            that you or others you are associated with will profit from is
            expressly prohibited in the brainstorm area (both posts and
            comments). The brainstorming area is for giving and getting
            suggestions, not marketing.
          </p>
          <p>
            <br />
            <strong>Rule 4</strong>
          </p>
          <p>
            Do not post or encourage the posting of sexual or suggestive content
            involving minors. Doing so will result in being banned. In the
            interest of public safety, we also will cooperate with local and
            federal law enforcement agencies in regard to identifying and
            prosecuting those who disregard this rule.
          </p>
          <p>
            <br />
            <strong>Rule 5</strong>
          </p>
          <p>
            Harrassment, bullying, and doxxing will not be tolerated. Do not
            post or threaten to post intimate or sexually-explicit materials
            containing others.
          </p>
          <p>
            <br />
            <strong>Rule 6</strong>
          </p>
          <p>
            You may call yourself a fictional name when utilizing the website.
            However, impersonating others is not allowed. Pretending to be
            another person may result in a ban and/or permanent account removal.{' '}
          </p>
          <p>
            <br />
            <strong>Rule 7</strong>
          </p>
          <p>
            Do not post vulgar, profane, or sexually explicit content in
            community areas. These areas are accessible by the public, and users
            are required to consider others. Violent and adult-themed topics for
            storylines can be discussed, but please use do not post graphic or
            explicit materials in community areas.
          </p>
          <p>
            <br />
            <strong>Rule 8</strong>
          </p>
          <p>
            Avoid posting illegal content or facilitating or soliciting
            prohibited or illicit transcations. You may not use Plot Producer to
            facilitate any transactions, but illicit transcations are
            particularly forbidden. Any attempts to use the site for such
            activities will be forwarded along to authorities.
          </p>
        </div>
        <div className='my-2'>
          {' '}
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Guidelines;
